<template>
<svg class="cnm-icon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_964_130329)">
<path d="M19.3334 7.53345V11.5334H15.3334" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.66663 18.2V14.2H8.66663" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.3404 10.8676C6.6786 9.91191 7.25339 9.05743 8.01115 8.38393C8.7689 7.71043 9.68492 7.23986 10.6737 7.01613C11.6625 6.7924 12.6919 6.8228 13.6658 7.1045C14.6397 7.38619 15.5263 7.91 16.243 8.62705L19.3371 11.5345M4.66663 14.2018L7.76077 17.1093C8.47746 17.8263 9.36411 18.3501 10.338 18.6318C11.3119 18.9135 12.3412 18.9439 13.33 18.7202C14.3189 18.4965 15.2349 18.0259 15.9926 17.3524C16.7504 16.6789 17.3252 15.8244 17.6634 14.8687" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_964_130329">
<rect width="16" height="16" fill="white" transform="translate(4 4.8667)"/>
</clipPath>
</defs>
</svg>
</template>