<template>
    <v-row class="mx-0 my-4">
        <v-col
            v-for="(type, key) in productsStore.filters.type"
            :key="key"
            cols="4"
            lg="12"
            class="pa-0"
        >
            <Checkbox
                v-model="selected"
                :label="type.name"
                :value="type.id"
            />
        </v-col>
    </v-row>
</template>

<script setup>
import { computed } from 'vue';
import { useProductsStore } from '@/store/products';
import Checkbox from '@/components/Common/Checkbox.vue';

const productsStore = useProductsStore();

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    delayed: {
        type: Boolean,
        default: false
    }
});

const selected = computed({
    get: () =>
        props.delayed ? productsStore.delayedFiltersSelected[props.name] : productsStore.filtersSelected[props.name],
    set: value =>
        props.delayed
            ? productsStore.addDelayedFilterSelected(props.name, value)
            : productsStore.addFilterSelected(props.name, value)
});
</script>
