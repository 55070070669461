import { defineStore } from 'pinia';

const TIMEOUT_MS = 100;

export const useLoadingStore = defineStore('loading', {
    state: () => ({
        loading: false
    }),

    getters: {
        isLoading: state => state.loading
    },

    actions: {
        /**
         * @param {Boolean} isLoading
         */
        setStatus(isLoading) {
            this.setLoading(isLoading);
        },

        /**
         * @param {Boolean} isLoading
         */
        setLoading(isLoading) {
            setTimeout(() => {
                this.loading = isLoading;
            }, TIMEOUT_MS);
        }
    }
});
