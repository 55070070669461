<template>
    <svg class="cnm-icon" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5608_11127)">
            <path d="M17.4166 13.395V15.77C17.4175 15.9905 17.3724 16.2087 17.284 16.4107C17.1957 16.6127 17.0662 16.7941 16.9037 16.9431C16.7412 17.0922 16.5494 17.2057 16.3406 17.2763C16.1317 17.3469 15.9104 17.3732 15.6908 17.3533C13.2547 17.0886 10.9147 16.2562 8.85872 14.9229C6.94591 13.7074 5.32419 12.0857 4.10872 10.1729C2.77078 8.10762 1.93816 5.7562 1.6783 3.30916C1.65852 3.09024 1.68454 2.8696 1.7547 2.66128C1.82486 2.45297 1.93763 2.26154 2.08582 2.0992C2.23402 1.93685 2.4144 1.80714 2.61547 1.71833C2.81654 1.62951 3.0339 1.58354 3.25372 1.58333H5.62872C6.01292 1.57955 6.38539 1.7156 6.6767 1.96613C6.96801 2.21665 7.15828 2.56456 7.21205 2.945C7.31229 3.70505 7.4982 4.45132 7.76622 5.16958C7.87273 5.45294 7.89578 5.76089 7.83264 6.05694C7.7695 6.353 7.62282 6.62475 7.40997 6.84L6.40455 7.84541C7.53153 9.82739 9.17258 11.4684 11.1546 12.5954L12.16 11.59C12.3752 11.3771 12.647 11.2305 12.943 11.1673C13.2391 11.1042 13.547 11.1272 13.8304 11.2337C14.5486 11.5018 15.2949 11.6877 16.055 11.7879C16.4395 11.8422 16.7907 12.0359 17.0418 12.3322C17.2929 12.6285 17.4263 13.0067 17.4166 13.395Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_5608_11127">
                <rect width="19" height="19" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
