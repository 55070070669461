<template>
    <BasePromiseDialog
        :title="title"
        :titleSize="titleSize"
        :text="text"
        :textSize="textSize"
        :closeDialog="closeDialog"
        :buttonBack="buttonBack"
        :layout="layout"
    >
        <div :class="[layout === 'h' ? 'ti-dialog__center' : 'ti-dialog__stacked', text === '' ? 'mt-10' : 'mt-4']">
            <v-text-field
                v-model="textInput"
                class="body-bold ti-dialog__text-field"
                variant="outlined"
                type="text"
                :placeholder="placeHolderText"
                density="compact"
                autofocus
                hide-details
                data-testid="dialog-text-input"
                @keydown.enter="closeDialog(textInput)"
            />

            <Button
                :text="button || $t('common.action.save')"
                class="px-8"
                :height="51"
                data-testid="dialog-button-save"
                @click="closeDialog(textInput)"
            />
        </div>
    </BasePromiseDialog>
</template>

<script setup>
import { ref } from 'vue';
import { closeDialog } from 'vue3-promise-dialog';

import BasePromiseDialog from '@/components/Common/dialogs/BasePromiseDialog.vue';

import Button from '@/components/Common/Button.vue';

defineProps({
    title: {
        type: String,
        default: ''
    },
    placeHolderText: {
        type: String,
        default: ''
    },
    buttonBack: {
        type: Boolean,
        default: false
    },
    text: {
        type: String,
        default: ''
    },
    button: {
        type: String,
        default: null
    },
    titleSize: {
        type: String,
        default: 'h4'
    },
    textSize: {
        type: String,
        default: 'h5'
    },
    layout: {
        type: String,
        default: 'h'
    }
});

const textInput = ref('');
</script>

<style lang="scss" scoped>
.ti-dialog__center {
    display: grid;
    grid-template-columns: auto rem(130px);
    column-gap: rem(20px);

    .ti-dialog__text-field {
        :deep(input) {
            @extend .body-bold;
            padding: rem(16px) rem(24px);
        }
    }
}
.ti-dialog__stacked {
    display: block;

    .ti-dialog__text-field {
        margin-bottom: rem(40px);
        :deep(input) {
            @extend .body-bold;
            padding: rem(16px) rem(24px);
        }
    }
    button {
        min-width: rem(175px);
        float: right;
    }
}
</style>
