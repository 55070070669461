export default {
    es: {
        EUR: {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard'
        },
        '€': {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard',
            locale: 'es-UK'
        },
        USD: {
            style: 'currency',
            currency: 'USD',
            notation: 'standard'
        },
        $: {
            style: 'currency',
            currency: 'USD',
            notation: 'standard'
        },
        CAD: {
            style: 'currency',
            currency: 'CAD',
            notation: 'standard'
        },
        GBP: {
            style: 'currency',
            currency: 'GBP',
            notation: 'standard'
        },
        '£': {
            style: 'currency',
            currency: 'GBP',
            notation: 'standard'
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        },
        percent: {
            style: 'percent',
            useGrouping: false
        }
    },
    en: {
        EUR: {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard'
        },
        '€': {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard'
        },
        USD: {
            style: 'currency',
            currency: 'USD',
            notation: 'standard'
        },
        $: {
            style: 'currency',
            currency: 'USD',
            notation: 'standard'
        },
        CAD: {
            style: 'currency',
            currency: 'CAD',
            notation: 'standard'
        },
        GBP: {
            style: 'currency',
            currency: 'GBP',
            notation: 'standard'
        },
        '£': {
            style: 'currency',
            currency: 'GBP',
            notation: 'standard'
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        },
        percent: {
            style: 'percent',
            useGrouping: false
        }
    },
    fr: {
        EUR: {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard'
        },
        '€': {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard',
            locale: 'es-UK'
        },
        USD: {
            style: 'currency',
            currency: 'USD',
            notation: 'standard'
        },
        $: {
            style: 'currency',
            currency: 'USD',
            notation: 'standard'
        },
        CAD: {
            style: 'currency',
            currency: 'CAD',
            notation: 'standard'
        },
        GBP: {
            style: 'currency',
            currency: 'GBP',
            notation: 'standard'
        },
        '£': {
            style: 'currency',
            currency: 'GBP',
            notation: 'standard'
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        },
        percent: {
            style: 'percent',
            useGrouping: false
        }
    },
    it: {
        EUR: {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard'
        },
        '€': {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard',
            locale: 'es-UK'
        },
        USD: {
            style: 'currency',
            currency: 'USD',
            notation: 'standard'
        },
        $: {
            style: 'currency',
            currency: 'USD',
            notation: 'standard'
        },
        CAD: {
            style: 'currency',
            currency: 'CAD',
            notation: 'standard'
        },
        GBP: {
            style: 'currency',
            currency: 'GBP',
            notation: 'standard'
        },
        '£': {
            style: 'currency',
            currency: 'GBP',
            notation: 'standard'
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        },
        percent: {
            style: 'percent',
            useGrouping: false
        }
    }
};
