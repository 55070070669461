export default {
    copyToClipboard,
    formatBytes,
    getUserFromToken,
    hash,
    isEmptyObject,
    inBoundsPosition,
    toggleFullScreen,
    uuid,
    removeElement
};

/**
 * Get the relative position of a item inside a container
 *
 * @param {Object} item
 * @param {Object} container
 *
 * @return {Object}
 */
function inBoundsPosition(item, container) {
    const itemRect = item.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    let x = 1, y = 1;

    if (itemRect.left + itemRect.width > containerRect.right) {
        x = containerRect.width - itemRect.width;
    } else if (itemRect.left > containerRect.left) {
        x = itemRect.left - containerRect.left;
    }

    if (itemRect.top + itemRect.height > containerRect.bottom) {
        y = containerRect.height - itemRect.height;
    } else if (itemRect.top > containerRect.top) {
        y = itemRect.top - containerRect.top;
    }

    return { x, y };
}

/**
 * Copy text to clipboard
 *
 * @param {String} data
 * @param {Boolean} notice     Show notice when the text has been copied
 */
function copyToClipboard(data) {
    let fake = document.createElement('textarea');
    fake.value = data;
    document.body.appendChild(fake);
    fake.select();
    document.execCommand('copy');
    document.body.removeChild(fake);
}

/**
 * Return the bytes formated
 *
 * @param {Number} bytes
 * @param {Number} decimals
 *
 * @return {Number}
 */
function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Decode a JWT Token to get the related user
 *
 * @param {String} token
 *
 * @return {Object}
 */
function getUserFromToken(token) {
    return JSON.parse(atob(token.split('.')[1]));
}

/*
 * Get a hash for the given string
 *
 * @param {String} s
 *
 * @return {String}
 */
function hash(s) {
    let hash = 0;
    for (let i = 0; i < s.length; i++) {
        let chr = s.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
}

/**
 * Switch into or out of full-screen mode
 *
 * @param {Boolean|null} value
 */
function toggleFullScreen(value) {
    const doc = window.document;
    const docEl = doc.documentElement;
    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen ||
        docEl.webkitRequestFullscreen || docEl.msRequestFullscreen;
    const exitFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen ||
        doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (value !== false && (!doc.fullscreenElement && !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement && !doc.msFullscreenElement)) {
        requestFullScreen.call(docEl);
    } else {
        exitFullScreen.call(doc);
    }
}

/**
 * Generate a random uuid
 *
 * @return {String}
 */
function uuid() {
    return Math.random().toString(16).slice(2);
}

/**
 * Check if a DOM element is a child of another
 *
 * @param {Object} parent
 * @param {Object} child
 */
function isDescendant(parent, child) {
    var node = child.parentNode;
    while (node != null) {
        if (node == parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}
    

/**
 * Remove a DOM element after a given time
 *
 * @param {Object} elem
 * @param {Integer} time
 */
function removeElement(elem, time) {
    elem.className += ' fadeout';
    setTimeout(() => {
        if (isDescendant(document.body, elem)) {
            document.body.removeChild(elem);
        }
    }, time);
}

/**
 * Check if an object is empty
 *
 * @param {Object} obj
 *
 * @returns {Boolean}
 */
function isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}