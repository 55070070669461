<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <mask
            id="path-1-inside-1_7103_252834"
            fill="white"
        >
            <rect
                x="3.55566"
                y="5.39062"
                width="16.8889"
                height="12.7778"
                rx="0.888889"
            />
        </mask>
        <rect
            x="3.55566"
            y="5.39062"
            width="16.8889"
            height="12.7778"
            rx="0.888889"
            stroke="white"
            stroke-width="3"
            mask="url(#path-1-inside-1_7103_252834)"
        />
        <ellipse
            cx="8.44455"
            cy="10.2795"
            rx="0.888889"
            ry="0.888889"
            fill="white"
        />
        <ellipse
            cx="12.0002"
            cy="10.2795"
            rx="0.888889"
            ry="0.888889"
            fill="white"
        />
        <ellipse
            cx="15.5559"
            cy="10.2795"
            rx="0.888889"
            ry="0.888889"
            fill="white"
        />
        <path
            d="M9 14.1719H15"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
        />
    </svg>
</template>
