import en from './en.json';
import es from './es.json';
import it from './it.json';
import fr from './fr.json';

/**
 * Here we define the default messages, which are the same as the agent messages.
 */
export default function defaultMessages() {
    return {
        en,
        es,
        it,
        fr
    };
}
