import { openDialog } from 'vue3-promise-dialog';
import ConfirmDialog from '@/components/Common/dialogs/ConfirmDialog.vue';
import TextInputDialog from '@/components/Common/dialogs/TextInputDialog.vue';

/**
 * This function allow to show The Confirmation Dialog
 *
 * @param {Object} payload - whit the next attributes
 * @param {String} payload.title
 * @param {String} payload.message
 * @param {String} payload.info - for show extra text plain info
 * @param {Boolean} payload.buttonNo - for show/hide No Button
 * @param {Boolean} payload.buttonCancel - for show/hide Cancel Button
 *
 * @return {Promise} with true value (YES option) or false value (NO) or null value (CANCEL or close)
 */
export async function confirm({ message, title = '', info = '', buttonNo = true, buttonCancel = false }) {
    return await openDialog(ConfirmDialog, {
        title,
        message,
        buttonYes: true,
        buttonNo,
        buttonCancel,
        typeConfirmation: true,
        info
    });
}

/**
 * This function allow to show The Information Dialog
 *
 * @param {Object} payload - whit the next attributes
 * @param {String} payload.title
 * @param {String} payload.message
 * @param {Boolean} payload.buttonCancel - for show/hide cancel Button
 *
 * @return {Promise} with null value (CANCEL or close)
 */
export async function inform({ title = '', message = '', buttonCancel = true }) {
    return await openDialog(ConfirmDialog, {
        title,
        message,
        buttonYes: false,
        buttonNo: false,
        buttonCancel,
        typeConfirmation: false
    });
}

/**
 * This function allow to show The Information Dialog with HTML Text, using i18n Component Interpolation
 * https://vue-i18n.intlify.dev/guide/advanced/component.html#slots-syntax-usage
 *
 * @param {Object} payload - whit the next attributes
 * @param {String} payload.i18nKey - i18n message key
 * @param {Object} payload.i18nConfig - Object with some key/variables to i18n named slots
 * @param {Object} payload.iconLeft - payload with properties {icon, size, color}
 * @param {String} payload.message - for show extra text plain info
 * @param {Boolean} payload.buttonCancel - for show/hide cancel Button
 */
export async function informHtmli18n({
    i18nKey,
    i18nConfig = {},
    iconLeft = {},
    message = '',
    buttonCancel = false,
    buttonClose = true
}) {
    return await openDialog(ConfirmDialog, {
        titleI18n: { key: i18nKey, ...i18nConfig },
        iconLeft,
        message,
        buttonYes: false,
        buttonNo: false,
        buttonCancel,
        buttonClose,
        typeConfirmation: false
    });
}

/**
 * This function allow to show The Text Input Dialog
 *
 * @param {Object} payload - whit the next attributes
 * @param {String} payload.title
 * @param {String} payload.placeHolderText - for show the placeHolder inside text input field
 * @param {Boolean} payload.buttonBack - for show/hide back Button
 *
 * @return {Promise} with the user input text or false value (buttonBack) or null value (close)
 */
export async function textInput({
    title = '',
    placeHolderText = '',
    buttonBack = false,
    text = '',
    button = null,
    titleSize = 'h4',
    textSize = 'h5',
    layout = 'h'
}) {
    return await openDialog(TextInputDialog, {
        title,
        placeHolderText,
        buttonBack,
        text,
        button,
        titleSize,
        textSize,
        layout
    });
}
