<template>
    <v-select
        v-model="locale"
        :items="locales"
        :menu-props="{
            contentClass: 'locale-menu-content'
        }"
        menu-icon="chevronDown"
        class="v-select-locale"
        item-title="name"
        item-value="id"
        variant="plain"
        density="compact"
        hide-details
        text
    >
        <template #selection="{ item }">
            <span class="body-medium text-uppercase text-dark">{{ item.value }}</span>
        </template>
    </v-select>
</template>

<script setup>
import { watch, ref } from 'vue';
import { useLocale } from 'vuetify';
import { storeToRefs } from 'pinia';

import { useLocaleStore } from '@/store/locales';

const props = defineProps({
    reloadPage: {
        type: Boolean,
        default: false
    }
});

const { locale, locales } = useLocaleSelect();

/**
 * Reusable functions specific to this component
 */
function useLocaleSelect() {
    // Global
    const { current } = useLocale();
    const localeStore = useLocaleStore();
    const { locales } = storeToRefs(localeStore);

    // Local
    const locale = ref('');

    locale.value = localeStore.getLocale;
    current.value = locale.value;

    watch(locale, () => {
        localeStore.setLocale(locale.value);

        if (props.reloadPage) {
            localeStore.clearLocales();
            window.location.reload();
        } else {
            current.value = locale.value;
        }
    });

    return {
        locale,
        locales
    };
}
</script>

<style lang="scss" scoped>
.v-select-locale {
    :deep(.v-field) {
        padding: 8px 12px;
    }
    :deep(.v-field__input) {
        padding: 0;
        text-transform: uppercase;
        height: auto;
        min-height: auto;
    }
    :deep(.v-field__append-inner) {
        padding: 0;
        padding-top: 0;
        svg {
            transition: none !important;
        }
    }
}
</style>
