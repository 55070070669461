import ApiService from '@/plugins/axiosCnm';

class CustomerApiServices {
    /**
     * @param {String|Number} customerId
     *
     * @return {Promise}
     */
    getCustomer(customerId) {
        return ApiService.get(`/customers/${customerId}`);
    }

    /**
     * @param {String|Number} customerId
     *
     * @return {Promise}
     */
    getCustomerAddresses(customerId) {
        return ApiService.get(`/customers/${customerId}/addresses`);
    }

    /**
     * @param {Object} params
     * @param {String} agentId
     *
     * @return {Promise}
     */
    getCustomers(params, agentId) {
        return ApiService.get(`/agents/${agentId}/customers`, {
            params: params
        });
    }

    /**
     * @param {String|Number} customerId
     * @param {Object} address
     *
     * @return {Promise}
     */
    createCustomerAddress(customerId, address) {
        const { name, street, city, zip, region, countryId } = address;

        return ApiService.post(`/customers/${customerId}/addresses`, { name, street, city, zip, region, countryId });
    }

    /**
     * @return {Promise}
     */
    getCustomerGroups() {
        return ApiService.get('/customer-groups');
    }

    /**
     * @return {Promise}
     */
    getCustomerTemplates() {
        return ApiService.get('customer-templates');
    }

    /**
     * @return {Promise}
     */
    getPaymentMethods(subagentId) {
        return ApiService.get(`/agents/${subagentId}/payment-methods`);
    }

    /**
     * @return {Promise}
     */
    getPaymentTerms(subagentId) {
        return ApiService.get(`/agents/${subagentId}/payment-terms`);
    }

    /**
     * @param {Object} customer
     *
     * @return {Promise}
     */
    createCustomer(customer) {
        return ApiService.post(`/customers`, customer);
    }
}

export default new CustomerApiServices();
