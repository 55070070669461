<template>
    <v-expansion-panels variant="accordion">
        <v-expansion-panel>
            <v-expansion-panel-title
                expand-icon=""
                collapse-icon=""
            >
                <div class="d-flex align-center justify-center w-100">
                    <span class="body-bold d-block text-center">
                        {{ props.title }}
                    </span>
                    <v-icon
                        icon="chevronDown"
                        size="22"
                        variant="plain"
                    />
                </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <slot></slot>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: true
    }
});
</script>

<style lang="scss" scoped>
:deep(.v-expansion-panel-title__overlay) {
    background: transparent;
    background: $white;
    color: $black;
}
:deep(.v-expansion-panel-text__wrapper) {
    padding: 8px 24px;
}
</style>
