<template>
<svg class="cnm-icon" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_964_130324)">
<path d="M21.6684 5.47682C21.1576 4.96582 20.5512 4.56046 19.8837 4.2839C19.2163 4.00734 18.5009 3.86499 17.7784 3.86499C17.0559 3.86499 16.3405 4.00734 15.673 4.2839C15.0056 4.56046 14.3991 4.96582 13.8884 5.47682L12.8284 6.53682L11.7684 5.47682C10.7367 4.44512 9.3374 3.86552 7.87837 3.86553C6.41933 3.86553 5.02006 4.44512 3.98837 5.47682C2.95667 6.50851 2.37708 7.90778 2.37708 9.36682C2.37708 10.8258 2.95667 12.2251 3.98837 13.2568L5.04837 14.3168L12.8284 22.0968L20.6084 14.3168L21.6684 13.2568C22.1794 12.7461 22.5847 12.1396 22.8613 11.4722C23.1378 10.8047 23.2802 10.0893 23.2802 9.36682C23.2802 8.64433 23.1378 7.92892 22.8613 7.26146C22.5847 6.594 22.1794 5.98757 21.6684 5.47682V5.47682Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_964_130324">
<rect width="24" height="24" fill="white" transform="translate(0.828369 0.866699)"/>
</clipPath>
</defs>
</svg>
</template>