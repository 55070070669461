import en from './en.json';
import es from './es.json';

const b2bMessages = [
    { key: 'en', value: en },
    { key: 'es', value: es }
].map(message => {
    message.value.id = 'b2b';

    return message;
});

export default b2bMessages;
