import dateHelper from '@/helpers/date';
import numberHelper from '@/helpers/number';
import arrayHelper from '@/helpers/array';
import generalHelper from '@/helpers/general';
import mediaHelper from '@/helpers/media';
import rulesHelper from '@/helpers/rules';

export default {
    install: (app) => {
        app.config.globalProperties.$dateHelper = dateHelper;
        app.config.globalProperties.$numberHelper = numberHelper;
        app.config.globalProperties.$arrayHelper = arrayHelper;
        app.config.globalProperties.$generalHelper = generalHelper;
        app.config.globalProperties.$mediaHelper = mediaHelper;
        app.config.globalProperties.$rulesHelper = rulesHelper;
    }
};
