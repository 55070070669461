<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5 14.2173C5.35044 13.604 6.05133 11.9045 6.05133 10.0122C6.05133 8.11986 7.45311 6.59551 8.154 6.06987M9.46817 5.0186C10.7823 4.05493 14.2517 2.91605 17.616 6.06987C20.9803 9.2237 19.0178 15.2684 17.616 17.8966"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M14.9874 8.17312C14.1989 7.64748 12.2014 6.91159 10.5192 8.17312C8.41657 9.75003 9.99357 13.1682 7.36523 16.5849"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16.039 10.2734C16.2143 11.1495 16.3544 13.4273 15.5134 15.5298C14.6723 17.6324 13.5859 19.3845 13.1479 19.9977M12.6222 10.2734C12.6222 11.4999 12.4645 14.3209 11.8337 15.7926C11.0452 17.6324 9.99386 18.9465 8.67969 19.7349"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
