<template>
    <v-autocomplete
        :items="items"
        :loading="loading"
        :label="label"
        variant="outlined"
        density="compact"
        :menu-icon="menuIcon"
        :noDataText="noDataI18nKey || `common.form.noDataText.${items.length ? 'filterNoMatch' : 'select'}`"
        :custom-filter="customFilter || customKeysFilter"
        :no-filter="noFilter"
        @update:model-value="updateModelValue"
    >
        <template
            v-if="$slots.selection"
            #selection="{ item }"
        >
            <label :class="`v-autocomplete__selection--${propsValues.selectedStyle}`">
                <slot
                    name="selection"
                    :item="item"
                    :itemRaw="item?.raw"
                ></slot>
            </label>
        </template>

        <template
            v-if="$slots.item"
            #item="{ props, item }"
        >
            <span :class="disabledItem(props)">
                <slot
                    name="item"
                    :props="props"
                    :item="item"
                    :itemRaw="item?.raw"
                    data-testid="autocomplete-item"
                ></slot>
            </span>
        </template>
        <template #append-item>
            <div v-intersect="endIntersect" />
        </template>
    </v-autocomplete>
</template>

<script setup>
const propsValues = defineProps({
    items: {
        type: Array,
        default: () => []
    },
    loading: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        default: ''
    },
    menuIcon: {
        type: String,
        default: 'chevronDown'
    },
    noDataI18nKey: {
        type: String
    },
    customFilter: {
        type: Function
    },
    filterKeys: {
        type: Array
    },
    selectedStyle: {
        type: String,
        default: 'dotted'
    },
    noFilter: {
        type: Boolean,
        default: false
    },
    allowDisabledOptions: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue', 'infinitescroll:items']);

const updateModelValue = value => {
    emit('update:modelValue', value);
    document.activeElement.blur();
};

const disabledItem = item => {
    if (!propsValues.allowDisabledOptions) {
        return '';
    }

    return !item?.title?.ordersAllowed ? 'v-autocomplete__disabled' : '';
};

const customKeysFilter = propsValues.filterKeys
    ? (itemID, queryText, item) => {
          const itemObj = item.raw;
          const searchText = queryText.toLowerCase();

          return propsValues.filterKeys.some(key => itemObj?.[key]?.toLowerCase().indexOf(searchText) > -1);
      }
    : undefined;

const endIntersect = (entries, observer, isIntersecting) => {
    if (isIntersecting) {
        emit('infinitescroll:items');
    }
};
</script>

<style lang="scss" scoped>
:deep(.v-autocomplete__selection) {
    max-width: 90%;
}
.v-autocomplete__selection {
    &--dotted {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
.v-autocomplete__disabled {
    pointer-events: none;
    opacity: 0.4;
}
</style>
