<template>
<svg class="cnm-icon" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_964_130330)">
<path d="M8.00004 15.5333C11.6819 15.5333 14.6667 12.5485 14.6667 8.86662C14.6667 5.18472 11.6819 2.19995 8.00004 2.19995C4.31814 2.19995 1.33337 5.18472 1.33337 8.86662C1.33337 12.5485 4.31814 15.5333 8.00004 15.5333Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 4.8667V8.8667L10.6667 10.2" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_964_130330">
<rect width="16" height="16" fill="white" transform="translate(0 0.866699)"/>
</clipPath>
</defs>
</svg>
</template>