import ApiService from '@/plugins/axiosCnm';

class DocumentApiServices {
    /**
     * @param {Object} params
     *
     * @returns {Promise}
     */
    getOrders({ customer, payload }) {
        return ApiService.get(`customers/${customer}/orders`, {
            params: payload
        });
    }

    /**
     * @param {Object} params
     *
     * @returns {Promise}
     */
    getInvoices({ customer, payload }) {
        return ApiService.get(`/customers/${customer}/documents/invoices`, {
            params: payload
        });
    }

    /**
     * @param {String|Number} customerId
     * @param {Object} params
     *
     * @returns {Promise}
     */
    getDeliveryNotes({ customer, payload }) {
        return ApiService.get(`/customers/${customer}/documents/delivery-notes`, {
            params: payload
        });
    }

    /**
     * @param {Object} params
     *
     * @returns {Promise}
     */
    getRefunds({ customer, payload }) {
        return ApiService.get(`/customers/${customer}/documents/refunds`, {
            params: payload
        });
    }

    /**
     * @param {String|Number} customerId
     * @param {String|Number} documentId
     *
     * @returns {Promise}
     */
    downloadDocument(customerId, documentId) {
        return ApiService.get(`/customers/${customerId}/documents/download/${documentId}`, {
            responseType: 'blob'
        });
    }

    /**
     * @param {String|Number} customerId
     * @param {String} documentId
     * @param {String} documentType
     *
     * @returns {Promise}
     */
    requestDocument(customerId, documentId, documentType) {
        return ApiService.post(`/customers/${customerId}/documents/request`, {
            documentId: documentId,
            documentType: documentType
        });
    }
}

export default new DocumentApiServices();
