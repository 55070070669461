import axios from 'axios';
import router from '@/router';
import { useUserStore } from '@/store/user';
import { HTTP_UNAUTHORIZED, HTTP_FORBIDDEN } from '@/data/common/responseStatus';

const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_CMS,
    headers: {
        'Content-Type': 'application/json'
    }
});

// The request interceptor ensures that we check for the token
// in local storage every time an ajax request is made
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('cnm-cms-jwt-token') || null;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        if (['get', 'post', 'patch', 'put', 'delete'].includes(config.method?.toLowerCase())) {
            config.params = config.params || {};
            config.params['locale'] = localStorage.getItem('cnm-locale') || 'es';
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// response interceptor (For common tasks when detect error status code on response)
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        if (err.response?.status === HTTP_UNAUTHORIZED || err.response?.status === HTTP_FORBIDDEN) {
            const userStore = useUserStore();

            userStore.logout();
            router.replace({ name: 'login' });
        }

        return Promise.reject(err);
    }
);

export default axiosInstance;
