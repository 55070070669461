{
  "common": {
    "code": "Código",
    "title": "Título",
    "description": "Descripción",
    "yes": "Sí",
    "no": "No",
    "ok": "Ok",
    "back": "Atrás",
    "percent": "%",
    "comment": "Comentario",
    "date": "Fecha",
    "expiration": "Vencimiento",
    "data": "Dato | Datos",
    "status": "Estado | Estados",
    "information": "Información",
    "see": "Ver",
    "colors": "Color | Colores",
    "total": "Total",
    "erp": "ERP",
    "b2b": "B2B",
    "and": "y",
    "form": {
      "placeHolder": {
        "date": "Seleccione una fecha",
        "comment": "Escribe aquí tu comentario (max {count} carácteres)",
        "email": "Escribe aquí los correos electrónicos separados por comas",
        "orderName": "Escribe aquí el nombre del pedido"
      },
      "noDataText": {
        "select": "No hay ningún elemento",
        "filterNoMatch": "Ningún elemento coincide con la búsqueda"
      }
    },
    "action": {
      "send": "Enviar",
      "save": "Guardar",
      "edit": "Editar",
      "delete": "Borrar",
      "search": "Buscar",
      "close": "Cerrar",
      "cancel": "Cancelar",
      "apply": "Aplicar",
      "continue": "Continuar",
      "confirm": "Confirmar",
      "copyToClipboard": "Copiar al portapapeles"
    },
    "dialog": {
      "confirmation": "CONFIRMACIÓN",
      "information": "INFORMACIÓN",
      "supportInfo": "Si necesitas soporte puedes enviar correo a {email}"
    },
    "notification": {
      "create": {
        "success": "Creado correctamente",
        "error": "Ocurrieron problemas creando el elemento"
      },
      "update": {
        "success": "Actualizado correctamente",
        "error": "Ocurrieron problemas actualizando el elemento"
      },
      "delete": {
        "success": "Eliminado correctamente",
        "error": "Ocurrieron problemas eliminando el elemento"
      },
      "read": {
        "error": "Ocurrieron problemas cargando algunos datos"
      },
      "send": {
        "success": "Operación realizada correctamente",
        "error": "Ocurrieron problemas realizando la acción"
      }
    },
    "statusOptions": {
      "confirmed": "Confirmado",
      "pending": "Pendiente de confirmación",
      "awaiting_confirmation": "Pendiente de confirmación",
      "awaiting_cofirmation": "Pendiente de confirmación",
      "canceled": "Cancelado",
      "cancelled": "Cancelado",
      "new": "Nuevo",
      "accepted": "Aceptado"
    }
  },
  "global": {
    "welcome": {
      "title": "Bienvenido al nuevo<br>B2B de Victoria!",
      "message": "Si es la primera vez que accedes, debes registrarte para poder tener acceso.",
      "contact": "*Para cualquier duda, estamos a tu disposición en <a href=\"mailto:{email}\">{email}</a>"
    },
    "selectLocale": "Selecciona un idioma",
    "menuLeft": {
      "myOrders": "Mis pedidos",
      "selections": "Selecciones",
      "favorites": "Favoritos",
      "ordersAndDocuments": "Pedidos y documentos",
      "communicationMaterial": "Material Comunicación",
      "clients": "Clientes"
    },
    "menuTop": {
      "myData": "Mis datos",
      "changeAgent": "Cambiar de agente",
      "goToCMS": "Ir al CMS",
      "logout": "Cerrar sesión"
    }
  },
  "auth": {
    "username": "Usuario / email",
    "password": "Contraseña",
    "repeatPassword": "Repetir contraseña",
    "login": "Iniciar sesión",
    "register": "Registro",
    "email": "Escribe aquí tu mail de alta",
    "forgotPassword": "¿Has olvidado la contraseña?",
    "passwordMatch": "El password debe coincidir",
    "currentPassword": "Escribe aquí tu contraseña actual",
    "newPassword": "Escribe aquí tu nueva contraseña",
    "repeatNewPassword": "Repite la nueva contraseña",
    "welcome": "¡Te damos la bienvenida",
    "selectAgent": "Elige el perfil que quieres visualizar",
    "changePassword": "Cambiar contraseña",
    "registerMessage": "Estamos revisando tu solicitud, recibirás un email confirmando la creación de tu nueva cuenta.",
    "forgotPasswordMessage": "Se ha enviado un enlace al correo {email}, con información para restablecer la contraseña.",
    "invalidLink": "El enlace no es válido o ha caducado",
    "approveUser": "¿Estás seguro de aprobar el usuario?",
    "yourSessionIsExpired": "Tu sesión ha expirado",
    "pleaseReInsertPassword": "Por favor introduce de nuevo tu contraseña",
    "ifNotCurrentUserYouCan": "Si {user} no es tu usuario puedes ",
    "changeUser": "cambiar de usuario",
    "errors": {
      "register": "Para activar la cuenta, ponte en contacto con nosotros en {email} (código: {code})",
      "login": "Ocurrieron problemas con la autenticación en el servidor",
      "invalidLink": "El enlace no es válido o ha caducado (código: {code})",
      "codeInformation": "Ocurrieron problemas con la autenticación en el servidor (código: {code})"
    },
    "success": {
      "approveUser": "Usuario aprobado correctamente",
      "passwordUpdated": "Contraseña actualizada correctamente"
    }
  },
  "user": {
    "info": {
      "myUser": "Mi usuario",
      "nif": "NIF",
      "nifIncludeLetters": "",
      "name": "Nombre",
      "customerName": "Nombre de cliente",
      "phone": "Teléfono",
      "cifNif": "CIF/NIF",
      "commercialReason": "Nombre comercial",
      "contactPerson": "Persona de contacto",
      "email": "Correo electrónico | Correos electrónicos",
      "clientGroup": "Grupo cliente",
      "template": "Modelo declaración tributaria",
      "agent": "Agente",
      "subagent": "Subagente",
      "userCode": "Código de usuario",
      "clientCode": "Código de cliente",
      "helpText": "Si necesitas modificar alguno de tus datos por favor manda un correo a {email}"
    },
    "placeHolder": {
      "customer": {
        "name": "Escriba aquí el nombre del nuevo cliente",
        "phone": "Escriba aquí el teléfono del nuevo cliente",
        "businessName": "Escriba aquí la razón social",
        "commercialReason": "Escriba aquí el nombre comercial",
        "contactPerson": "Escriba el nombre de la persona de contacto",
        "email": "Escriba el correo electrónico",
        "group": "Seleccione un grupo de cliente",
        "template": "Seleccione un modelo declaración tributaria",
        "subagent": "Seleccione un subagente"
      }
    },
    "unsubscribe": "Darme de baja",
    "dialog": {
      "unsubscribe": "¿Estás seguro de solicitar darte de baja?"
    }
  },
  "cms": {
    "cmsEditContent": "Editar contenido",
    "newHome": "Crear nueva Home",
    "setName": "Nombrar",
    "writeName": "Escribe el nombre para la nueva Home",
    "selectedBackground": "Fondo seleccionado",
    "loadedOptions": "Opciones cargadas",
    "importContent": "Importar contenido",
    "acceptedImages": "Formatos aceptados JPG, PNG, MP4 y GIF.\n            Tamaño máximo recomendado 100MB.\n            Medidas recomendadas 1760 x 574px.",
    "fileOverLimit": "El archivo excede el tamaño máximo",
    "fileInHome": "No se puede borrar el archivo ya que se está utilizando en {homes}.",
    "confirmDelete": "¿Seguro que quieres eliminar el contenido del banner?",
    "addProducts": "Añadir productos",
    "selectProducts": "Seleccionar productos",
    "selectedProducts": "Productos seleccionados",
    "searchByCode": "Buscar por código",
    "name": "Nombre",
    "homes": "Homes",
    "products": "Productos",
    "selectBannerMedia": "Selecciona imagen / video para el banner",
    "admin": "CMS Admin",
    "menu": {
      "b2b": "Ir a B2B",
      "text": "Texto del banner",
      "content": "Contenido del banner",
      "products": "Productos",
      "preview": "Previsualización",
      "priceGroups": "Grupos de precio"
    },
    "tabs": {
      "notice": "Aviso",
      "extraInfo": "Info extra",
      "copy": "Copy"
    },
    "fields": {
      "phone": "Teléfono",
      "description": "Descripción",
      "title": "Título",
      "showInfo": "Mostrar información"
    }
  },
  "product": {
    "sizes": "Talla | Tallas",
    "from": "Desde",
    "to": "hasta",
    "sizeCurve": "Curva de tallas",
    "info": "Info",
    "composition": "Composición",
    "pvp": "P.V.P.",
    "pc": "P.C.",
    "legendGreen": "+ 20 pares en stock",
    "legendYellow": "- 20 pares en stock",
    "legendRed": "Sin stock actual",
    "legendBlack": "No disponible",
    "units": "unidades",
    "season": "Temporada",
    "sku": "SKU",
    "prices": "Precio | Precios",
    "quantities": "Cantidad | Cantidades",
    "serie": "Serie"
  },
  "address": {
    "name": "Nombre",
    "street": "Dirección | Direcciones",
    "city": "Población",
    "city2": "Ciudad",
    "region": "Provincia",
    "zip": "Código postal",
    "country": "País",
    "addTitle": "Añadir nueva dirección",
    "editTitle": "Editar dirección",
    "areAddressesEquals": "¿La dirección de facturación y envío son iguales?",
    "addressName": "Nombre de la dirección",
    "placeHolder": {
      "default": {
        "select": "Seleccione una dirección de envío"
      },
      "customer": {
        "nif": "Escriba aquí el NIF del {item}",
        "name": "Escriba aquí un nombre para su nueva dirección",
        "street": "Escriba aquí la dirección del {item}",
        "city": "Escriba aquí la población del {item}",
        "region": "Escriba aquí la provincia del {item}",
        "zip": "Escriba aquí el código postal del {item}",
        "country": "Seleccione un país"
      },
      "shipping": {
        "name": "Escriba aquí el nombre",
        "street": "Escriba aquí la dirección de envío ",
        "city": "Escriba aquí la población",
        "region": "Escriba aquí la provincia",
        "zip": "Escriba aquí el código postal"
      }
    }
  },
  "order": {
    "billingAddress": "Dirección de facturación",
    "client": "Cliente",
    "contactData": "Datos de contacto",
    "returnToOrder": "Volver al pedido",
    "origin": "Origen",
    "quantityOrdered": "C. pedido",
    "quantityPending": "C. pendiente",
    "quantitySent": "C. enviada",
    "complete": "Completo | Completos",
    "pending": "Pendiente | Pendientes",
    "detailTitle": "Información del pedido",
    "reference": "Referencia de pedido",
    "articles": "Artículo | Artículos",
    "pendingQuantities": "Cantidad pendiente | Cantidades pendientes",
    "repeat": "Repetir pedido",
    "pairTotal": "Cantidad total",
    "requestedDate": "Fecha de servicio solicitada",
    "payment": {
      "method": "Método de pago",
      "form": "Forma de pago",
      "bank": "Banco | Bancos",
      "bankName": "Nombre banco",
      "iban": "IBAN",
      "terms": "Términos de pago",
      "observations": "Observaciones",
      "clientObservations": "Observaciones de cliente",
      "allowsOrders": "Permite pedidos",
      "bankCode": "Código de Banco",
      "bankInfo": "Si necesitas modificar o añadir un banco por favor manda un correo a {email}",
      "placeHolder": {
        "form": "Seleccione una forma de pago",
        "bankName": "Escriba aquí el nombre del banco",
        "iban": "Escriba aquí el IBAN",
        "terms": "Seleccione unos términos de pago"
      }
    },
    "shipping": {
      "address": "Dirección de envío | Direcciones de envío",
      "addressInfo": "Si necesitas añadir o editar una dirección envíanos un correo a {email}",
      "editAddress": "Editar dirección de envío",
      "addAddress": "Añadir nueva dirección de envío"
    },
    "price": {
      "shippingCost": "Coste del envío",
      "transportCost": "Coste de transporte {price}",
      "freeShippingFrom": "Portes gratuitos a partir de {price}",
      "amount": "Importe",
      "grossAmount": "Importe bruto",
      "taxes": "Impuestos",
      "type": "Tipo",
      "base": "Base",
      "amountTax": "Cuota",
      "discounts": "Descuentos",
      "surcharges": "Recargos",
      "totalSum": "Suma total",
      "subtotal": "Subtotal",
      "subtotalWithDiscounts": "Subtotal con descuentos",
      "taxAmount": "Importe de impuestos",
      "paymentTermsDiscount": "Descuento por términos de pago",
      "customerDiscount": "Descuento de cliente",
      "shipping": "Coste de envío",
      "surchargeAmount": "Recargo",
      "freeShippingFromWarning": "*Por favor, tenga en cuenta que para pedidos inferiores a {amount}, se aplicará un cargo por envío"
    },
    "customer": {
      "label": "cliente",
      "new": "nuevo cliente",
      "existing": "cliente existente",
      "select": "Seleccione el cliente",
      "businessName": "Razón social",
      "create": {
        "success": "Su nuevo cliente ha sido guardado correctamente. | Su nuevo cliente ha sido guardado correctamente. Ya puede completar su pedido.",
        "error": "Ocurrieron problemas guardando el cliente"
      }
    },
    "notifications": {
      "success": "Su pedido se ha realizado correctamente. En breve recibirá un mail con toda la información."
    }
  },
  "cart": {
    "title": "Cesta",
    "addToCart": "Añadir a la cesta",
    "doOrder": "Realizar pedido",
    "totalProduct": "Total producto actual",
    "totalProducts": "Total productos",
    "quantity": "uds",
    "emptyMessage": "Tu cesta está vacía, añade productos para poder realizar el siguiente pedido.",
    "goToCart": "Ir a la cesta",
    "added": {
      "addedProducts": "Se han añadido los productos a la cesta",
      "addedQuantity": "Se ha añadido 1 unidad a la cesta. | Se han añadido {count} unidades a la cesta.",
      "addedQuantities": "Se han añadido las unidades a la cesta.",
      "noAddedQuantityNoAvailable": "1 modelo no ha podido ser añadido porque no se encuentra disponible en este momento. | {count} modelos no han podido ser añadidos porque no se encuentran disponibles en este momento."
    },
    "order": {
      "completeTitle": "Completar datos de pedido",
      "requestedIssueDate": "Fecha de expedición solicitada",
      "make": "Realizar pedido",
      "yourOrderReference": "Su referencia de pedido"
    }
  },
  "filter": {
    "filters": "Filtros",
    "search": "Búsqueda",
    "searchIn": "Buscar en",
    "size": "Talla",
    "nameASC": "Nombre de A a Z",
    "nameDESC": "Nombre de Z a A",
    "priceASC": "Precio de menor a mayor",
    "priceDESC": "Precio de mayor a menor",
    "skuASC": "Referencia de menor a mayor",
    "skuDESC": "Referencia de mayor a menor",
    "type": "Tipo",
    "sole": "Piso",
    "section": "Sección",
    "availability": "Disponibilidad",
    "availableStock": "Stock disponible",
    "color": "Color",
    "category": "Categoría",
    "availabilityQuote": "Mostrar solo productos con stock",
    "boy": "Niño",
    "girl": "Niña",
    "children": "Niños",
    "searchMinLength": "La búsqueda debe tener al menos {count} caracteres",
    "withStock": "Disponibilidad"
  },
  "selections": {
    "title": "Selecciones",
    "advice": "Para crear una nueva selección, escoge el modelo color con el corazón y dale un nombre a tu selección. Posteriormente podrás enviarla por email y/o convertirla en pedido.",
    "btnNew": "Nueva Selección",
    "addNameTitle": "Nombrar nueva selección",
    "renameTitle": "Renombrar “{name}”.",
    "placeHolderName": "Nombre de la selección",
    "btnMoveToCart": "Mover Selección a la cesta",
    "returnToItem": "Volver a la selección",
    "dialog": {
      "deleteMessage": "¿Estás seguro de eliminar esta Selección? | ¿Estás seguro de eliminar estas {count} selecciones?",
      "deleteProducts": "¿Estás seguro de eliminar este producto de la selección? | ¿Estás seguro de eliminar estos {count} productos-colores de la selección?",
      "moveToCartMessage": "¿Estás seguro de mover esta selección a la cesta?",
      "cartAlreadyExists": "Atención, la cesta ya contiene productos.",
      "saveTo": "Guardar en Selecciones",
      "sendByEmail": "Enviar selección por email"
    },
    "notification": {
      "create": {
        "success": "Selección creada correctamente",
        "error": "Ocurrieron problemas creando la selección"
      },
      "addProduct": {
        "success": "El producto se ha guardado en “{name}” correctamente",
        "error": "Ocurrieron problemas guardando la selección del producto"
      },
      "removeProduct": {
        "success": "Eliminado correctamente de la selección",
        "error": "Ocurrieron problemas al deseleccionar el producto"
      },
      "emailSent": {
        "success": "Email enviado correctamente",
        "error": "Ocurrieron problemas enviando el email"
      }
    }
  },
  "rules": {
    "required": "Requerido",
    "nameFormat": "Nombre inválido",
    "emailFormat": "Correo inválido",
    "phoneNumber": "Número de teléfono inválido",
    "password": "La contraseña debe contener al menos 8 caracteres, una letra mayúscula, una letra minúscula y uno de estos símbolos: .-_ !?",
    "passwordConfirmation": "La confirmación de contraseña no coincide",
    "passwordNewDifferent": "La contraseña nueva debe ser diferente a la actual",
    "number": "Número inválido",
    "web": "Sitio web inválido",
    "decimalNumber": "Número decimal inválido",
    "minCharacter": "Mínimo 8 caracteres",
    "emailTaken": "El correo ya ha sido tomado",
    "usernameTaken": "El nombre de usuario ya ha sido tomado",
    "username": "El nombre de usuario es inválido",
    "zipCode": "El código postal es inválido",
    "nif": "El Nif es inválido",
    "iban": "El Iban es inválido",
    "maxCharacter": "Máximo {max} caracteres",
    "customerOrderId": "El código de referencia solo puede contener letras mayúsculas (A-Z y Ñ), números (0-9), espacios y los siguientes caracteres especiales: , . _ - / ( )."
  },
  "table": {
    "of": "of",
    "rowCount": "Filas por página"
  },
  "documents": {
    "order": "Pedido | Pedidos",
    "invoice": "Factura | Facturas",
    "deliveryNote": "Albarán | Albaranes",
    "refund": "Abono | Abonos",
    "ordersAndDocuments": "Pedidos y documentos",
    "zipCode": "El código postal es inválido",
    "nif": "El Nif es inválido",
    "iban": "El Iban es inválido",
    "pdf": "PDF",
    "requestPDF": "Solicitar PDF",
    "requestedPDF": "PDF solicitado",
    "availablePDF": "PDF disponible",
    "completePDF": "PDF completo",
    "pendingPDF": "PDF pares pendientes",
    "orderActions": "Acciones pedido",
    "package": "Bulto | Bultos",
    "notification": {
      "request": {
        "success": "Solicitud de PDF realizada correctamente. Esta operación tardará unos minutos.",
        "error": "Ocurrieron problemas realizando la solicitud de PDF"
      },
      "download": {
        "success": "Descarga de PDF realizada correctamente",
        "error": "Ocurrieron problemas realizando la descarga de PDF"
      }
    }
  },
  "notFound": {
    "title": "Oops!",
    "subtitle": "Algo ha ido mal",
    "message": "La url a la que intentas acceder no existe o ha sido eliminada.",
    "back": "Volver a la página de inicio"
  },
  "$vuetify": {
    "input": {
      "clear": ""
    }
  },
  "icon": {
    "title": {
      "trash": "Eliminar",
      "resizeToggled": "Plegar",
      "resize": "Desplegar",
      "clearQuantity": "Limpiar cantidades",
      "copyChecked": "Copiar",
      "copy": "Copiar",
      "paste": "Pegar",
      "search": "Buscar",
      "cart": "Cesta",
      "cartMarked": "Cesta",
      "heart": "Añadir a favoritos",
      "info": "Información"
    }
  }
}
