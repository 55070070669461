<template>
    <h5
        v-if="props.title"
        class="text-h5 text-center mt-4"
    >
        {{ props.title }}
    </h5>

    <v-item-group
        v-model="selected"
        multiple
    >
        <v-row
            class="my-4 mx-0"
            dense
        >
            <v-col
                v-for="(sole, key) in productsStore.filters.soles"
                :key="key"
                lg="6"
                class="sole-col"
            >
                <v-item
                    v-slot="{ isSelected, toggle }"
                    :value="sole.id"
                >
                    <v-card
                        class="d-flex align-center py-1 h-100"
                        dark
                        :class="{
                            'card-selected--black': isSelected
                        }"
                        :variant="isSelected ? 'elevated' : 'outlined'"
                        @click="toggle"
                    >
                        <v-scroll-y-transition>
                            <div class="body-small-medium flex-grow-1 text-center">
                                {{ sole.name }}
                            </div>
                        </v-scroll-y-transition>
                    </v-card>
                </v-item>
            </v-col>
        </v-row>
    </v-item-group>
</template>

<script setup>
import { computed } from 'vue';
import { useProductsStore } from '@/store/products';

const productsStore = useProductsStore();

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    title: {
        type: String,
        required: false
    },
    delayed: {
        type: Boolean,
        default: false
    }
});

const selected = computed({
    get: () =>
        props.delayed ? productsStore.delayedFiltersSelected[props.name] : productsStore.filtersSelected[props.name],
    set: value =>
        props.delayed
            ? productsStore.addDelayedFilterSelected(props.name, value)
            : productsStore.addFilterSelected(props.name, value)
});
</script>

<style lang="scss" scoped>
@media (max-width: 1279px) {
    .sole-col {
        min-width: calc(100% / 6);
        max-width: calc(100% / 6);
    }
}

.card-selected--black {
    background: $black;
    color: $white;
}
</style>
