import ApiService from '@/plugins/axiosCnm';

class AgentsApiService {
    /**
     * Get the agents
     *
     * @return {Promise}
     */
    getAgents() {
        return ApiService.get('/agents');
    }

    /**
     * @return {Promise}
     */
    getSubagents(agentID) {
        return ApiService.get(`/agents/${agentID}/subagents`);
    }
}

export default new AgentsApiService();
