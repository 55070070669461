import axios from 'axios';
import router from '@/router';
import { useUserStore } from '@/store/user';
import { HTTP_UNAUTHORIZED, HTTP_FORBIDDEN } from '@/data/common/responseStatus';

/**
 * Default axios instance with the common configuration
 */
const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_VICTORIA,
    headers: {
        'Content-Type': 'application/json'
    }
});

/**
 * AXIOS INSTANCE that not redirect to login if HTTP_UNAUTHORIZED or HTTP_FORBIDDEN status codes are received
 * */
export const axiosWithoutRedirect = axios.create({
    baseURL: import.meta.env.VITE_API_VICTORIA,
    headers: {
        'Content-Type': 'application/json'
    }
});

// TODO: To remove in prod - Add credentials to query string
const ON4U_NAME = import.meta.env.VITE_ON4U_NAME;
const ON4U_VALUE = import.meta.env.VITE_ON4U_VALUE;

// The request interceptor ensures that we check for the token
// in local storage every time an ajax request is made
axiosInstance.interceptors.request.use(requestInterceptor, error => Promise.reject(error));

axiosWithoutRedirect.interceptors.request.use(requestInterceptor, error => Promise.reject(error));

function requestInterceptor(config) {
    const token = localStorage.getItem('cnm-jwt-token') || null;
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (['get', 'post', 'patch', 'put', 'delete'].includes(config.method?.toLowerCase())) {
        config.params = config.params || {};
        config.params['locale'] = localStorage.getItem('cnm-locale') || 'es';
    }

    // TODO: To remove in prod - Add credentials to query string
    config.params[ON4U_NAME] = ON4U_VALUE;

    return config;
}

// response interceptor (For common tasks when detect error status code on response)
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        if (err.response?.status === HTTP_UNAUTHORIZED || err.response?.status === HTTP_FORBIDDEN) {
            const userStore = useUserStore();

            userStore.logout();
            router.replace({ name: 'login' });
        }

        return Promise.reject(err);
    }
);

export default axiosInstance;
