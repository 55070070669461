export default {
    base64ToBlob,
    pausePlay
};

/**
 * Convert base64 to raw binary data held in a string
 *
 * @param {String} dataURI
 *
 * @return {Blob}
 */
function base64ToBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    // const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab]);
}

/**
 * Pause any playing video and play the current one
 *
 * @param {Object} e
 */
function pausePlay(e) {
    const playing = e.target.currentTime > 0 && !e.target.paused && !e.target.ended;
    // Pause all videos
    const videos = document.querySelectorAll('video');
    for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
    }
    // If the current video was already paused, start playing
    if (!playing) {
        e.target.play();
    }
}