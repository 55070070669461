<template>
    <AuthLayout>
        <template #auth-content>
            <v-card
                class="pa-10 pt-0"
                elevation="0"
            >
                <v-card-text class="px-0 pt-10 pb-5">
                    <h3 class="text-center text-black mb-8">{{ $t('auth.welcome') }}<br />{{ user?.displayName }}!</h3>
                    <v-form>
                        <label class="text-center">
                            <span class="body-medium text-dark d-block mb-2">{{ $t('auth.selectAgent') }}</span>
                            <Autocomplete
                                v-model="agentSelected"
                                :items="agents"
                                item-title="name"
                                item-value="id"
                                class="d-block w-50 agent-select"
                                data-testid="agent-select"
                            />
                        </label>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-0">
                    <Button
                        class="w-100 text-h4"
                        :disabled="disabledContinue"
                        :text="$t('common.action.continue')"
                        @click="continueToCNM()"
                    />
                </v-card-actions>
            </v-card>
        </template>
    </AuthLayout>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useAgentsStore } from '@/store/agents';
import { useUserStore } from '@/store/user';
import { useNotificationStore } from '@/store/common/notification';
import { useLoadingStore } from '@/store/common/loading';

import { useResetStore } from '@/composables/resetStore/useResetStore';

import AuthLayout from '@/layouts/auth/AuthLayout.vue';
import Autocomplete from '@/components/Common/formInput/Autocomplete.vue';
import Button from '@/components/Common/Button.vue';

// External
const router = useRouter();
const { t } = useI18n();

// Global
const { setLoading } = useLoadingStore();
const notificationStore = useNotificationStore();

// Agents
const agentsStore = useAgentsStore();
const agents = ref([]);
const { agentSelected, user, disabledContinue, continueToCNM } = useSelectAgent();

// Reset store
const { executeResetStore } = useResetStore();

onMounted(async () => {
    setLoading(true);
    try {
        await agentsStore.loadAgents();
        agents.value = agentsStore.getAgents;
    } catch (error) {
        notificationStore.error({ message: t('common.notification.read.error') });
    }

    setLoading(false);
});

function useSelectAgent() {
    const agentSelected = ref('');
    const disabledContinue = computed(() => {
        return agentSelected.value === '';
    });
    const userStore = useUserStore();
    const user = ref('');
    user.value = userStore.user;
    const continueToCNM = () => {
        executeResetStore();
        const agent = agents.value.find(agent => agent.id === agentSelected.value);
        userStore.setAgentSelected(agent);
        router.push({ name: 'home' });
    };

    return {
        agentSelected,
        user,
        disabledContinue,
        continueToCNM
    };
}
</script>

<style lang="scss" scoped>
.app-bar {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 30px;
}
.agent-select {
    margin: 0 auto;
    :deep(.v-autocomplete__selection-text) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
