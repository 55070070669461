/**
 * Retrieves the data from the provided jwt.
 *
 * @param {string} token - The authentication token.
 *
 * @returns {Object|null} - The object decoded from the token, or null if the token is empty.
 */
const getUserFromToken = token => {
    if (!token) {
        return null;
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
};

/**
 * Calculates the expiration date of the token.
 *
 * @param {Object} options - The options object.
 * @param {string} options.token - The authentication jwt.
 * @param {Object} options.data - The decoded token data.
 *
 * @returns {Date|null} - The expiration date of the token, or null if the token or data is empty.
 */
const getExpirationDate = ({ token, data }) => {
    if (!token && !data) {
        return null;
    }

    const info = data ?? getUserFromToken(token);
    const expirationToken = info.exp;
    const expirationDate = new Date(expirationToken * 1000);

    return expirationDate;
};

export { getUserFromToken, getExpirationDate };
