<template>
    <v-item-group
        v-model="selected"
        multiple
    >
        <v-row
            class="my-4 mx-0"
            dense
        >
            <v-col
                v-for="(size, key) in productsStore.filters.size"
                :key="key"
            >
                <v-item
                    v-slot="{ isSelected, toggle }"
                    :value="size.value"
                >
                    <v-card
                        class="d-flex align-center py-1"
                        :class="isSelected ? 'bg-black text-grey-scale-5' : ''"
                        dark
                        variant="outlined"
                        @click="toggle"
                    >
                        <v-scroll-y-transition>
                            <div class="body-small-medium flex-grow-1 text-center">
                                {{ size.label }}
                            </div>
                        </v-scroll-y-transition>
                    </v-card>
                </v-item>
            </v-col>
        </v-row>
    </v-item-group>
</template>

<script setup>
import { computed } from 'vue';
import { useProductsStore } from '@/store/products';

const productsStore = useProductsStore();

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    delayed: {
        type: Boolean,
        default: false
    }
});

const selected = computed({
    get: () =>
        props.delayed ? productsStore.delayedFiltersSelected[props.name] : productsStore.filtersSelected[props.name],
    set: value =>
        props.delayed
            ? productsStore.addDelayedFilterSelected(props.name, value)
            : productsStore.addFilterSelected(props.name, value)
});
</script>

<style lang="scss" scoped>
:deep(.v-col) {
    @media (max-width: 1279px) {
        max-width: calc(100% / 6);
        min-width: calc(100% / 6);
    }
    @media (min-width: 1280px) and (max-width: 1650px) {
        max-width: calc(100% / 3);
        min-width: calc(100% / 3);
    }
    @media (min-width: 1651px) and (max-width: 1919px) {
        max-width: calc(100% / 3);
        min-width: calc(100% / 3);
    }
    @media (min-width: 1920px) {
        max-width: calc(100% / 3);
        min-width: calc(100% / 3);
    }
}
</style>
