<template>
    <v-card-text class="px-0 pt-10 pb-3">
        <v-form v-model="isValidForm">
            <label>
                <span class="body-medium text-dark d-block mb-4">
                    {{ $t('user.info.nif') }} {{ $t('user.info.nifIncludeLetters') }}
                </span>
                <v-text-field
                    v-model="user.nif"
                    class="d-block mb-4 body-light"
                    variant="outlined"
                    type="text"
                    density="compact"
                    @keydown.enter.prevent="doRegister"
                />
            </label>

            <label>
                <span class="body-medium text-dark d-block mb-4">{{ $t('user.info.clientCode') }}</span>
                <v-text-field
                    v-model="user.code"
                    class="d-block mb-4 body-light"
                    variant="outlined"
                    type="text"
                    density="compact"
                    @keydown.enter.prevent="doRegister"
                />
            </label>

            <label>
                <span class="body-medium text-dark d-block mb-4">{{ $t('auth.email') }}</span>
                <v-text-field
                    v-model="user.email"
                    class="d-block mb-4 body-light"
                    variant="outlined"
                    type="text"
                    density="compact"
                    :rules="[$rulesHelper.required, $rulesHelper.validEmail]"
                    @keydown.enter.prevent="doRegister"
                />
            </label>
        </v-form>
    </v-card-text>
    <v-card-actions class="pa-0">
        <Button
            class="w-100 text-h4"
            :text="$t('auth.register')"
            :disabled="!isValidForm"
            :loading="loading"
            @click="doRegister"
        />
    </v-card-actions>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useNotificationStore } from '@/store/common/notification';
import { informHtmli18n } from '@/helpers/dialogs/commons';
import Button from '@/components/Common/Button.vue';

import { useUserStore } from '@/store/user';

// Register
const { loading, doRegister, isValidForm, user } = useRegister();

/**
 * Reusable functions specific to this component
 */
function useRegister() {
    // External
    const { t } = useI18n();

    // Global
    const notificationStore = useNotificationStore();

    // User
    const userStore = useUserStore();
    const user = ref({
        nif: '',
        code: '',
        email: ''
    });

    // Misc
    const loading = ref(false);
    const isValidForm = ref(false);

    const doRegister = async () => {
        if (!isValidForm.value) {
            return;
        }

        loading.value = true;

        try {
            const { nif, code, email } = user.value;
            await userStore.register({
                nif,
                code,
                email
            });

            const i18nKey = 'auth.registerMessage';
            const i18nConfig = { email };
            await informHtmli18n({ i18nKey, i18nConfig });
        } catch (error) {
            const EMAIL_B2B = import.meta.env.VITE_EMAIL_B2B;
            const code = error?.response?.data?.code;
            notificationStore.error({ message: t('auth.errors.register', { email: EMAIL_B2B, code }) });
        } finally {
            loading.value = false;
        }
    };

    return {
        user,
        loading,
        isValidForm,
        doRegister
    };
}
</script>

<style lang="scss" scoped>
.body-light {
    :deep(input) {
        padding: 18px 32px;
    }
}
</style>
