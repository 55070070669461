import { h } from 'vue';

import calendar from '@/assets/icons/svg/calendar.icon.vue';
import cartChecked from '@/assets/icons/svg/cartChecked.icon.vue';
import cartMarked from '@/assets/icons/svg/cartMarked.icon.vue';
import cart from '@/assets/icons/svg/cart.icon.vue';
import check from '@/assets/icons/svg/check.icon.vue';
import checkCircle from '@/assets/icons/svg/checkCircle.icon.vue';
import chevronDown from '@/assets/icons/svg/chevronDown.icon.vue';
import chevronLeft from '@/assets/icons/svg/chevronLeft.icon.vue';
import chevronLeftBig from '@/assets/icons/svg/chevronLeftBig.icon.vue';
import chevronLeftSingle from '@/assets/icons/svg/chevronLeftSingle.icon.vue';
import chevronRight from '@/assets/icons/svg/chevronRight.icon.vue';
import chevronRightSingle from '@/assets/icons/svg/chevronRightSingle.icon.vue';
import chevronUp from '@/assets/icons/svg/chevronUp.icon.vue';
import chevronDownDesktop from '@/assets/icons/svg/chevronDownDesktop.icon.vue';
import chevronLeftIpad from '@/assets/icons/svg/chevronLeftIpad.icon.vue';
import chevronRightIpad from '@/assets/icons/svg/chevronRightIpad.icon.vue';
import chevronUpDesktop from '@/assets/icons/svg/chevronUpDesktop.icon.vue';
import clock from '@/assets/icons/svg/clock.icon.vue';
import closeCircle from '@/assets/icons/svg/closeCircle.icon.vue';
import close from '@/assets/icons/svg/close.icon.vue';
import closeThin from '@/assets/icons/svg/closeThin.icon.vue';
import copyChecked from '@/assets/icons/svg/copyChecked.icon.vue';
import copy from '@/assets/icons/svg/copy.icon.vue';
import edit from '@/assets/icons/svg/edit.icon.vue';
import file from '@/assets/icons/svg/file.icon.vue';
import heart from '@/assets/icons/svg/heart.icon.vue';
import heartFilled from '@/assets/icons/svg/heartFilled.icon.vue';
import image from '@/assets/icons/svg/image.icon.vue';
import info from '@/assets/icons/svg/info.icon.vue';
import link from '@/assets/icons/svg/link.icon.vue';
import listGrouped from '@/assets/icons/svg/listGrouped.icon.vue';
import list from '@/assets/icons/svg/list.icon.vue';
import logout from '@/assets/icons/svg/logout.icon.vue';
import menu from '@/assets/icons/svg/menu.icon.vue';
import order from '@/assets/icons/svg/order.icon.vue';
import paste from '@/assets/icons/svg/paste.icon.vue';
import plus from '@/assets/icons/svg/plus.icon.vue';
import refresh from '@/assets/icons/svg/refresh.icon.vue';
import save from '@/assets/icons/svg/save.icon.vue';
import search from '@/assets/icons/svg/search.icon.vue';
import selected from '@/assets/icons/svg/selected.icon.vue';
import selector from '@/assets/icons/svg/selector.icon.vue';
import sort from '@/assets/icons/svg/sort.icon.vue';
import trash from '@/assets/icons/svg/trash.icon.vue';
import modeColor from '@/assets/icons/svg/modeColor.icon.vue';
import modeModel from '@/assets/icons/svg/modeModel.icon.vue';
import input from '@/assets/icons/svg/input.icon.vue';
import inputChecked from '@/assets/icons/svg/inputChecked.icon.vue';
import noStock from '@/assets/icons/svg/noStock.icon.vue';
import resize from '@/assets/icons/svg/resize.icon.vue';
import resizeToggled from '@/assets/icons/svg/resizeToggled.icon.vue';
import folder from '@/assets/icons/svg/folder.icon.vue';
import text from '@/assets/icons/svg/text.icon.vue';
import euro from '@/assets/icons/svg/euro.icon.vue';
import exit from '@/assets/icons/svg/exit.icon.vue';
import eye from '@/assets/icons/svg/eye.icon.vue';
import shoe from '@/assets/icons/svg/shoe.icon.vue';
import user from '@/assets/icons/svg/user.icon.vue';
import home from '@/assets/icons/svg/home.icon.vue';
import play from '@/assets/icons/svg/play.icon.vue';
import phone from '@/assets/icons/svg/phone.icon.vue';
import pencil from '@/assets/icons/svg/pencil.icon.vue';
import showPassword from '@/assets/icons/svg/showPassword.icon.vue';
import hidePassword from '@/assets/icons/svg/hidePassword.icon.vue';
import send from '@/assets/icons/svg/send.icon.vue';
import touch from '@/assets/icons/svg/touch.icon.vue';
import keyboard from '@/assets/icons/svg/keyboard.icon.vue';
import exclamation from '@/assets/icons/svg/exclamation.icon.vue';

const icons = {
    calendar,
    cartChecked,
    cartMarked,
    cart,
    check,
    checkCircle,
    chevronDown,
    chevronDownDesktop,
    chevronLeft,
    chevronLeftIpad,
    chevronLeftSingle,
    chevronLeftBig,
    chevronRight,
    chevronRightSingle,
    chevronRightIpad,
    chevronUp,
    chevronUpDesktop,
    clock,
    closeCircle,
    close,
    closeThin,
    clear: close,
    collapse: chevronUp,
    copyChecked,
    copy,
    edit,
    expand: chevronDown,
    file,
    heart,
    heartFilled,
    image,
    info,
    link,
    listGrouped,
    list,
    logout,
    menu,
    order,
    paste,
    plus,
    refresh,
    save,
    search,
    selected,
    selector,
    sort,
    trash,
    modeColor,
    modeModel,
    input,
    inputChecked,
    noStock,
    resize,
    resizeToggled,
    folder,
    text,
    euro,
    exit,
    eye,
    shoe,
    user,
    home,
    play,
    phone,
    pencil,
    showPassword,
    hidePassword,
    send,
    touch,
    keyboard,
    exclamation
};

const custom = {
    component: props => h(icons[props.icon])
};

export default {
    defaultSet: 'custom',
    aliases: icons,
    sets: {
        custom
    }
};
