<template>
    <v-card-text class="px-0 pt-10 pb-5">
        <v-form v-model="isValidForm">
            <label>
                <span class="body-medium text-dark d-block mb-4">{{ $t('auth.username') }}</span>
                <v-text-field
                    v-model="user.email"
                    class="d-block mb-4 body-light"
                    variant="outlined"
                    type="text"
                    density="compact"
                    data-testid="email"
                    :rules="[$rulesHelper.required, $rulesHelper.validEmail]"
                    @keydown.enter.prevent="doLogin"
                />
            </label>
            <label>
                <span class="body-medium text-dark d-block mb-4">{{ $t('auth.password') }}</span>
                <v-text-field
                    v-model="user.password"
                    :type="showPassword ? 'text' : 'password'"
                    class="d-block mb-4 body-light"
                    variant="outlined"
                    density="compact"
                    data-testid="password"
                    :rules="[$rulesHelper.required]"
                    :append-inner-icon="showPassword ? 'hidePassword' : 'showPassword'"
                    @click:append-inner="showPassword = !showPassword"
                    @keydown.enter.prevent="doLogin"
                />
            </label>
        </v-form>

        <router-link
            :to="{ name: 'forgot-password' }"
            class="body-medium text-center text-dark font-weight-bold d-block mb-4 text-decoration-none"
        >
            {{ $t('auth.forgotPassword') }}
        </router-link>
    </v-card-text>
    <v-card-actions class="pa-0">
        <Button
            class="w-100 text-h4"
            :text="$t('auth.login')"
            :disabled="!isValidForm"
            :loading="loading"
            data-testid="submit"
            @click="doLogin"
        />
    </v-card-actions>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useNotificationStore } from '@/store/common/notification';
import Button from '@/components/Common/Button.vue';

import { useResetStore } from '@/composables/resetStore/useResetStore';
import { useUserStore } from '@/store/user';

// Login
const { user, loading, isValidForm, doLogin, showPassword } = useLogin();

// Reset store
const { executeResetStore } = useResetStore();

/**
 * Reusable functions specific to this component
 */
function useLogin() {
    // External
    const { t } = useI18n();
    const router = useRouter();

    // Global
    const notificationStore = useNotificationStore();

    // User
    const userStore = useUserStore();

    const user = ref({
        email: '',
        password: ''
    });

    const showPassword = ref(false);

    // Misc
    const loading = ref(false);
    const isValidForm = ref(false);

    const doLogin = async () => {
        if (!isValidForm.value) {
            return;
        }

        loading.value = true;

        try {
            await userStore.login({
                email: user.value.email,
                password: user.value.password
            });

            executeResetStore();

            router.replace({ name: 'home' });
        } catch (error) {
            const code = error?.response?.data?.code;
            notificationStore.error({ message: t('auth.errors.codeInformation', { code }) });
        } finally {
            loading.value = false;
        }
    };

    return {
        user,
        loading,
        isValidForm,
        doLogin,
        showPassword
    };
}
</script>

<style lang="scss" scoped>
.body-light {
    :deep(input) {
        padding: rem(18px) rem(32px);
    }
}

:deep(.v-field__append-inner) {
    background: transparent;
    align-items: center;
    padding: 0;
}
</style>
