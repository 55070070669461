// Utilities
import { defineStore } from 'pinia';
import { useUserStore } from '@/store/user';
import DocumentApiService from '@/services/DocumentApiService';

function getDefaultState() {
    return {
        documentsFilter: {
            customer: null,
            search: '',
            date: '',
            origin: null
        }
    };
}

export const useDocumentsStore = defineStore('documents', {
    state: getDefaultState,

    getters: {
        getCustomerFilter() {
            return this.documentsFilter.customer;
        }
    },

    actions: {
        /**
         * Reset state
         *
         * @param {Object} state
         */
        resetState() {
            const newState = getDefaultState();
            Object.keys(newState).forEach(key => {
                this.$state[key] = newState[key];
            });
        },

        /**
         * @param {Object} payload
         *
         * @returns {Promise}
         */
        loadOrders(payload) {
            return new Promise((resolve, reject) => {
                const { customer, date, ...payloadData } = payload;

                const { isCustomer } = useUserStore();
                const customerSelected = isCustomer ? useUserStore().getCustomerId : customer;

                DocumentApiService.getOrders({
                    customer: customerSelected,
                    payload: {
                        ...payloadData,
                        date: date ? date : undefined,
                        search: payload.search?.length >= 3 ? payload.search : undefined
                    }
                })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * @param {Object} payload
         *
         * @returns {Promise}
         */
        loadInvoices(payload) {
            return new Promise((resolve, reject) => {
                const { isCustomer } = useUserStore();
                const { customer, ...payloadData } = payload;
                const customerSelected = isCustomer ? useUserStore().getCustomerId : customer;
                DocumentApiService.getInvoices({ customer: customerSelected, payload: payloadData })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * @param {Object} payload
         *
         * @returns {Promise}
         */
        loadDeliveryNotes(payload) {
            return new Promise((resolve, reject) => {
                const { isCustomer } = useUserStore();
                const { customer, ...payloadData } = payload;
                const customerSelected = isCustomer ? useUserStore().getCustomerId : customer;
                DocumentApiService.getDeliveryNotes({ customer: customerSelected, payload: payloadData })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * @param {Object} payload
         *
         * @returns {Promise}
         */
        loadRefunds(payload) {
            return new Promise((resolve, reject) => {
                const { isCustomer } = useUserStore();
                const { customer, ...payloadData } = payload;
                const customerSelected = isCustomer ? useUserStore().getCustomerId : customer;
                DocumentApiService.getRefunds({ customer: customerSelected, payload: payloadData })
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * @param {Blob} documentId
         *
         * @returns {Promise}
         */
        downloadPDF({ customerId, documentId }) {
            return new Promise((resolve, reject) => {
                DocumentApiService.downloadDocument(customerId, documentId)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * @param {String} documentId
         * @param {String} documentType
         *
         * @returns {Promise}
         */
        requestPDF(customerId, documentId, documentType) {
            return new Promise((resolve, reject) => {
                DocumentApiService.requestDocument(customerId, documentId, documentType)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * @param {String} filter.field
         * @param {String} filter.value
         *
         */
        updateFilterSettings({ field, value }) {
            this.documentsFilter[field] = value;
        }
    }
});
