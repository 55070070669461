/**
 * Function to adapt the data to the format that the API expects
 *
 * @param {Object} data
 * @param {Object} billingAddress
 * @param {Array} bankAccounts
 * @param {Array} comments
 * @param {String} priceGroupId
 *
 * @returns {Object}
 */
const newCustomerTransformer = ({ data, billingAddress, bankAccounts, comments, priceGroupId, agentId }) => {
    const { subagent, ...customer } = data;

    customer['subagentId'] = subagent || agentId;
    customer['priceGroupId'] = priceGroupId;
    customer['tin'] = billingAddress.nif;

    const { nif, ...billingAddressData } = billingAddress;
    customer['billingAddress'] = billingAddressData;

    customer['comments'] = comments;

    const { name, iban } = bankAccounts[0];
    if (iban) {
        customer['bankAccounts'] = [
            {
                name: name || '',
                iban: iban
            }
        ];
    }

    return customer;
};

export { newCustomerTransformer };
