<template>
    <SemanticIcon
        icon="heart"
        data-testid="favorite-icon"
        @click.stop="selectFavoritesSelections()"
    />
</template>

<script setup>
import { toRef } from 'vue';
import { openDialog } from 'vue3-promise-dialog';
import { useI18n } from 'vue-i18n';

import AddToSelectionsDialog from '@/components/Selections/AddToSelectionsDialog.vue';
import { useSelectionsStore } from '@/store/selections';
import { textInput } from '@/helpers/dialogs/commons';
import { useLoadingStore } from '@/store/common/loading';
import { useNotificationStore } from '@/store/common/notification';

import SemanticIcon from '@/components/Common/SemanticIcon.vue';

const props = defineProps({
    selections: {
        type: Array,
        required: true
    }
});

const emit = defineEmits(['update:favorite']);

// Selections
const { selectFavoritesSelections } = useFavoritesSelections({ selections: toRef(props, 'selections') });

/**
 * Reusable functions specific to this component
 */
function useFavoritesSelections({ selections }) {
    const { t } = useI18n();
    const selectionsStore = useSelectionsStore();
    const { setLoading } = useLoadingStore();
    const notificationStore = useNotificationStore();

    const selectFavoritesSelections = async () => {
        // for load the selections if are not loaded
        let hasSomeSelectionCreated = true;
        if (!selectionsStore.getSelections?.length) {
            setLoading(true);
            await selectionsStore.loadSelections();
            setLoading(false);

            hasSomeSelectionCreated = !!selectionsStore.getSelections?.length;
        }

        let dialogResult;
        if (hasSomeSelectionCreated) {
            // for open main dialog that allow select the selections
            dialogResult = await openDialog(AddToSelectionsDialog, {
                items: selectionsStore.getSelections,
                selectedItemIds: selections.value,
                updateSelectionCallback
            });
        }

        // add new selection (because don't have or because was pressed the button "new selection")
        if (!hasSomeSelectionCreated || dialogResult === false) {
            const name = await textInput({
                title: t('selections.addNameTitle'),
                placeHolderText: t('selections.placeHolderName'),
                buttonBack: true
            });

            // for back button
            if (name === false) {
                return selectFavoritesSelections();
            }

            // for create new selection
            if (name !== null) {
                const newSelection = await selectionsStore.createSelection({ name });
                notificationStore.success({ message: t('selections.notification.addProduct.success', { name }) });

                updateSelectionCallback(newSelection, true);
            }
        }
    };

    /**
     * @param {Object} item - selection instance
     * @param {Boolean} isSelected
     */
    function updateSelectionCallback(item, isSelected) {
        emit('update:favorite', { selectionId: item.id, isSelected });
    }

    return {
        selectFavoritesSelections
    };
}
</script>
