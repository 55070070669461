<template>
    <svg
        class="cnm-icon"
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            y="0.866699"
            width="32"
            height="32"
            rx="16"
            fill="black"
        />
        <path
            d="M9.43878 25.6666C9.18155 25.6666 8.95245 25.5902 8.75149 25.4375C8.55053 25.2848 8.4179 25.0878 8.35359 24.8467L5.62856 15.0317C5.56425 14.7906 5.60846 14.5735 5.76119 14.3806C5.91392 14.1877 6.1189 14.0912 6.37613 14.0912H11.1992L15.6605 7.5801C15.7409 7.48364 15.8293 7.40727 15.9258 7.35101C16.0223 7.29474 16.1348 7.2666 16.2634 7.2666C16.392 7.2666 16.5046 7.29474 16.601 7.35101C16.6975 7.40727 16.7859 7.48364 16.8663 7.5801L21.3035 14.0912H26.2472C26.4883 14.0912 26.6853 14.1877 26.838 14.3806C26.9907 14.5735 27.0349 14.7906 26.9706 15.0317L24.2215 24.8467C24.1572 25.0878 24.0245 25.2848 23.8236 25.4375C23.6226 25.5902 23.3935 25.6666 23.1363 25.6666H9.43878ZM9.67993 24.2197H22.8951L25.3308 15.5382H7.2684L9.67993 24.2197ZM12.9596 14.0912H19.5431L17.0873 10.4798C16.6913 9.89741 15.8334 9.89595 15.4354 10.477L12.9596 14.0912Z"
            fill="white"
        />
        <path
            d="M19.2728 18.0095L15.8442 21.4381L14.2858 19.8797"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
