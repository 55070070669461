import { defineStore } from 'pinia';
import CmsApiService from '@/services/CmsApiService';
import ProductsApiServices from '@/services/ProductsApiServices';

const TEXTS_STRUCTURE = {
    notice: {
        phone: '',
        desc: '',
        show: true
    },
    extraInfo: {
        desc: '',
        show: true
    },
    copy: {
        title: '',
        desc: '',
        show: true
    }
};

export const useCMSStore = defineStore('cms', {
    state: () => ({
        language: 'es',
        data: [],
        selectedHomeId: null,
        files: [],
        productList: [],
        productsSelected: [],
        priceGroups: []
    }),

    getters: {
        /**
         * @returns {Array}
         */
        getLanguages(state) {
            return Object.keys(state.getSelectedHome?.texts || {});
        },

        /**
         * @returns {String}
         */
        getData(state) {
            return state.data;
        },

        /**
         * @returns {Object}
         */
        getSelectedHome(state) {
            return state.data.find(home => home.id === state.selectedHomeId);
        },

        /**
         * @returns {Object}
         */
        getSelectedHomeTexts(state) {
            let texts = state.getSelectedHome?.texts[state.language] ?? TEXTS_STRUCTURE;

            if (!('copy' in texts) || !('desc' in texts.copy)) {
                texts.copy.desc = '';
            }

            return texts;
        },

        /**
         * @returns {Object}
         */
        getFiles(state) {
            return state.files;
        },

        /**
         * @returns {Object}
         */
        fileInHomes(state) {
            return fileId => {
                let homes = [];
                state.data.forEach(home => {
                    if (home.content.selected === fileId) {
                        homes.push(home.name);
                    }
                });

                return homes;
            };
        },

        /**
         * @returns {Object}
         */
        getSelectedFile(state) {
            return state?.files?.find(file => file.id === state.getSelectedHome?.content?.selected);
        },

        /**
         * @returns {Object}
         */
        getProducts(state) {
            return state.getSelectedHome.products;
        },

        /**
         * @returns {Object}
         */
        getProductList(state) {
            return state.productList.filter(prod => !prod.selected);
        },

        /**
         * @returns {Object}
         */
        getSelectedProductList(state) {
            return state.productList.filter(prod => prod.selected);
        }
    },

    actions: {
        /**
         * Save CMS JWT token
         *
         * @param {String} token
         */
        saveCmsJwtToken(token) {
            localStorage.removeItem('cnm-cms-jwt-token');
            localStorage.setItem('cnm-cms-jwt-token', token);
        },

        /**
         * Create a new Home
         *
         * @param {Object} title
         * @param {String} title.title
         *
         * @returns {Promise}
         */
        createHome({ title }) {
            return new Promise((resolve, reject) => {
                CmsApiService.createHome({ title })
                    .then(response => {
                        this.data.push(response.data);
                        resolve();
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * Get price groups from On4U API
         */
        async fetchPriceGroups() {
            try {
                const priceGroupsCNM = await ProductsApiServices.getPriceGroups();
                const PriceGroupsHome = await CmsApiService.getPriceGroupsHomes();

                this.priceGroups = priceGroupsCNM.data.map(group => {
                    const home = PriceGroupsHome.data.find(home => home.priceGroupId === group.id);

                    return {
                        id: group.id,
                        erpCode: group.erpCode,
                        home: home?.homeId ?? null
                    };
                });
            } catch (err) {
                console.error(err);
            }
        },

        /**
         * Save homes for price groups
         */
        savePriceGroupsHomes() {
            return new Promise((resolve, reject) => {
                CmsApiService.savePriceGroupsHomes({ priceGroups: this.priceGroups })
                    .then(() => resolve())
                    .catch(err => reject(err));
            });
        },

        /**
         * Update home title
         *
         * @returns {Promise}
         */
        async updateHomeTitle() {
            await CmsApiService.updateHomeTitle({
                id: this.getSelectedHome.id,
                title: this.getSelectedHome.name
            });
        },

        async getHomeByPriceGroup(priceGroupId) {
            const response = await CmsApiService.getHomeByPriceGroup({ priceGroupId });

            return response.data;
        },

        /**
         * Set language
         *
         * @param Number position
         */
        setCurrentLanguage(position) {
            this.language = position;
        },

        /**
         * Set selection
         *
         * @param String id
         */
        setSelectedHome(id) {
            this.selectedHomeId = id;
        },

        /**
         * Save texts
         */
        saveTexts() {
            return new Promise((resolve, reject) => {
                CmsApiService.saveTexts({
                    id: this.getSelectedHome.id,
                    texts: this.getSelectedHome.texts
                })
                    .then(() => resolve())
                    .catch(err => reject(err));
            });
        },

        async getMedia() {
            const files = await CmsApiService.getMedia();
            this.files = files.data;
        },

        /**
         * Upload media
         * @param {Object} file
         */
        async uploadMedia({ file }) {
            const files = await CmsApiService.uploadMedia({ file });
            this.files = files.data;
        },

        /**
         * Delete media
         *
         * @param number id
         */
        async deleteMedia(id) {
            const files = await CmsApiService.deleteMedia({ id });
            this.files = files.data;
        },

        async updateHomeBanner() {
            await CmsApiService.updateHomeBanner({
                id: this.getSelectedHome.id,
                banner: this.getSelectedHome.content.selected
            });
        },

        /**
         * Set File for banner
         *
         * @param String id
         */
        setSelectedFile(id) {
            this.getSelectedHome.content.selected = id;
        },

        /**
         * Mark product to be deleted (dragging)
         *
         * @param String id
         */
        markProductToDelete(id) {
            const [ref, color] = id.split('·');
            const product = this.getSelectedHome.products.find(prod => prod.ref == ref && prod.color == color);
            product.deleted = true;

            return product;
        },

        /**
         * Delete products marked for deleting
         */
        deleteProducts() {
            this.getSelectedHome.products = this.getSelectedHome.products.filter(prod => prod.deleted !== true);
        },

        /**
         * Unmark all products for deleting
         */
        undeleteProducts() {
            this.getSelectedHome.products.forEach(prod => (prod.deleted = false));
        },

        /**
         * Insert product in a determinate position
         *
         * @param Object product
         * @param String position
         */
        insertProduct(product, position) {
            this.getSelectedHome.products.splice(position, 0, product);
        },

        /**
         * Expand space before product to paste
         *
         * @param Number position
         */
        expandProduct(position) {
            this.unexpandProducts();
            const indexDeleted = this.getProducts.findIndex(prod => prod.deleted);
            if (indexDeleted != -1 && indexDeleted < position) {
                position++;
            }
            if (this.getSelectedHome.products[position] !== undefined) {
                this.getSelectedHome.products[position].expanded = true;
            }
        },

        /**
         * Unexpand all products
         */
        unexpandProducts() {
            this.getSelectedHome.products.forEach(prod => (prod.expanded = false));
        },

        loadHomeList() {
            return new Promise((resolve, reject) => {
                CmsApiService.getHomeList()
                    .then(response => {
                        this.data = response.data.homes;
                        this.selectedHomeId = response.data.homes[0].id;
                        resolve();
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * Load product list TODO
         *
         * @param String ref
         */
        async loadProductList(ref) {
            if (ref === null || ref.length < 1) {
                this.productList.length = 0;

                return;
            }
            const response = [
                {
                    ref: '8813100',
                    color: '20',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/1.png', '/images/static/home/2.png', '/images/static/home/10.png']
                },
                {
                    ref: '8823100',
                    color: '21',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/3.png']
                },
                {
                    ref: '8833100',
                    color: '22',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/4.png']
                },
                {
                    ref: '8843100',
                    color: '23',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/5.png']
                },
                {
                    ref: '8853100',
                    color: '24',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/6.png']
                },
                {
                    ref: '8863100',
                    color: '25',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/7.png']
                },
                {
                    ref: '8873100',
                    color: '26',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/8.png']
                },
                {
                    ref: '8883100',
                    color: '27',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/9.png']
                },
                {
                    ref: '8893100',
                    color: '23',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/5.png']
                },
                {
                    ref: '8903100',
                    color: '24',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/6.png']
                },
                {
                    ref: '8913100',
                    color: '25',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/7.png']
                },
                {
                    ref: '8923100',
                    color: '26',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/8.png']
                },
                {
                    ref: '8933100',
                    color: '27',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/9.png']
                },
                {
                    ref: '8943100',
                    color: '23',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/5.png']
                },
                {
                    ref: '8953100',
                    color: '24',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/6.png']
                },
                {
                    ref: '8963100',
                    color: '25',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/7.png']
                },
                {
                    ref: '8973100',
                    color: '26',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/8.png']
                },
                {
                    ref: '8983100',
                    color: '27',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/9.png']
                },
                {
                    ref: '8993100',
                    color: '23',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/5.png']
                },
                {
                    ref: '8703100',
                    color: '24',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/6.png']
                },
                {
                    ref: '8713100',
                    color: '25',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/7.png']
                },
                {
                    ref: '8723100',
                    color: '26',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/8.png']
                },
                {
                    ref: '8733100',
                    color: '27',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/9.png']
                },
                {
                    ref: '8743100',
                    color: '23',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/5.png']
                },
                {
                    ref: '8753100',
                    color: '24',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/6.png']
                },
                {
                    ref: '8763100',
                    color: '25',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/7.png']
                },
                {
                    ref: '8773100',
                    color: '26',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/8.png']
                },
                {
                    ref: '8783100',
                    color: '27',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/9.png']
                },
                {
                    ref: '8793100',
                    color: '23',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/5.png']
                },
                {
                    ref: '8603100',
                    color: '24',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/6.png']
                },
                {
                    ref: '8613100',
                    color: '25',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/7.png']
                },
                {
                    ref: '8623100',
                    color: '26',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/8.png']
                },
                {
                    ref: '8633100',
                    color: '27',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/9.png']
                },
                {
                    ref: '8643100',
                    color: '23',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/5.png']
                },
                {
                    ref: '8653100',
                    color: '24',
                    name: 'Wing patch contraste nylon',
                    img: ['/images/static/home/6.png']
                }
            ];
            await new Promise(resolve => setTimeout(resolve, 500)); //Simulates loading time

            // Filter results to hide already added products.
            this.productList = response.filter(
                productToFilter =>
                    this.getProducts.findIndex(
                        productToFind =>
                            productToFilter.ref === productToFind.ref && productToFilter.color === productToFind.color
                    ) == -1
            );
        },

        /**
         * Save selected products
         */
        saveSelectedProducts() {
            this.getSelectedHome.products = [...this.getSelectedHome.products, ...this.getSelectedProductList];
            this.productList.length = 0;
        }
    }
});
