<template>
    <v-btn-toggle
        variant="outlined"
        density="compact"
        multiple
        divided
        class="w-100"
    >
        <v-btn
            v-for="(item, key) in props.filterItems"
            :key="key"
            :value="item[props.itemValue]"
            class="w-33-exact"
        >
            <span class="body-small-medium">{{ item.name }}</span>
        </v-btn>
    </v-btn-toggle>
</template>

<script setup>
const props = defineProps({
    filterItems: {
        type: Array,
        default: () => []
    },
    itemValue: {
        type: String,
        default: 'id'
    }
});
</script>
