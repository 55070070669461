import { useCustomerStore } from '@/store/customer';
import { useDocumentsStore } from '@/store/documents';
import { useProductsStore } from '@/store/products';

export function useResetStore() {
    const executeResetStore = () => {
        useCustomerStore().resetState();
        useDocumentsStore().resetState();
        useProductsStore().resetState();
    };

    return {
        executeResetStore
    };
}
