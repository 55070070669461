<template>
    <v-dialog
        v-model="isVisible"
        class="imageModalDialog"
        transition="scale-transition"
    >
        <v-card>
            <v-btn
                class="closeBtn"
                flat
                variant="plain"
                position="absolute"
                icon="close"
                @click="close"
            ></v-btn>
            <v-img
                class="imageModal"
                :src="imageStore.image"
            ></v-img>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { computed } from 'vue';
import { useImageModalStore } from '@/store/common/imageModal';

const imageStore = useImageModalStore();

const isVisible = computed({
    get() {
        return imageStore.isVisible;
    },
    set(newValue) {
        imageStore.setStatus(newValue);
    }
});

function close() {
    imageStore.hideModal();
}
</script>
<style scoped>
.imageModalDialog {
    max-width: 50vw;
}
.closeBtn {
    top: 0.5rem;
    right: 0.5rem;
    z-index: 1;
}
</style>
