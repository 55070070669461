import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import BugsnagPerformance from '@bugsnag/browser-performance';

const BUGSNAG_API_KEY = import.meta.env.VITE_BUGSNAG_API_KEY;

export default {
    install: app => {
        if (!BUGSNAG_API_KEY) {
            return;
        }

        Bugsnag.start({
            apiKey: BUGSNAG_API_KEY,
            plugins: [new BugsnagPluginVue()]
        });
        BugsnagPerformance.start({ apiKey: BUGSNAG_API_KEY });
        const bugsnagVue = Bugsnag.getPlugin('vue');
        app.use(bugsnagVue);
    }
};
