<template>
    <v-btn
        :rounded="0"
        :variant="props.bordered ? 'outlined' : 'tonal'"
        :loading="loading"
        :disabled="disabled"
        :class="[props.bordered ? 'text-black bg-white' : 'text-white bg-black']"
        :height="props.height"
    >
        <template #prepend>
            <slot name="prepend" />
        </template>

        {{ props.text }}

        <template #append>
            <slot name="append" />
        </template>
    </v-btn>
</template>

<script setup>
const props = defineProps({
    text: {
        type: String
    },
    loading: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    bordered: {
        type: Boolean,
        default: false
    },
    height: {
        type: Number,
        default: 56
    }
});
</script>
