import { useI18n } from 'vue-i18n';

import { useNotificationStore } from '@/store/common/notification';

import { useProductsStore } from '@/store/products';
import { useSelectionStore } from '@/store/selection';

/**
 * @param {Function} updateFavoriteProductLocallyFn - function for update the favorite/selection product state locally (if null is used a default function)
 */
export function useProductToSelections(updateFavoriteProductLocallyFn = null) {
    // External
    const { t } = useI18n();

    // Global
    const notificationStore = useNotificationStore();

    // Selection/Favorite Product
    const selectionStore = useSelectionStore();
    const productsStore = useProductsStore();

    const _updateFavoriteProductLocallyFn = updateFavoriteProductLocallyFn ?? defaultUpdateFavoriteProductLocally;

    /**
     * Update favorite product color on API (add/remove from selection) and emit a event to allow perform this operation locally
     *
     * @params {Array} selectionIds
     * @params {Object} product
     */
    const updateFavoriteProduct = async (productReference, colorId, { selectionId, isSelected }) => {
        // for simulate success process (avoid reaction delay)
        _updateFavoriteProductLocallyFn({ productReference, colorId, selectionId, isSelected });

        // for sync (add/remove) a product-color to selection

        const isUpdateSuccess = isSelected
            ? await selectionStore.addProductToSelection(selectionId, productReference, colorId)
            : await selectionStore.removeProductsFromSelection([`${productReference}-${colorId}`], selectionId);

        if (!isUpdateSuccess) {
            // undo successful process when an error occurs
            _updateFavoriteProductLocallyFn({ productReference, colorId, selectionId, isSelected: !isSelected });
        }
    };

    /**
     * default function for deselect/select favorite products locally
     *
     * @param {Object} payload - with the next attributes
     * @param {String} payload.productReference
     * @param {String} payload.colorId
     * @param {String} payload.selectionId
     * @param {Boolean} payload.isSelected
     */
    function defaultUpdateFavoriteProductLocally({ productReference, colorId, selectionId, isSelected }) {
        productsStore.updateFavoriteProductLocally({ productReference, colorId, selectionId, isSelected });
    }

    return {
        updateFavoriteProduct
    };
}
