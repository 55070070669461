// Vue core
import App from './App.vue';
import { createApp } from 'vue';
import { registerPlugins } from '@/plugins';
import { tapAndSwipe } from '@/directives/directives';

// Styles
import '@/assets/css/general.scss';

const app = createApp(App);

registerPlugins(app);

app.mount('#app');
app.directive('tapAndSwipe', tapAndSwipe);
