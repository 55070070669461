<template>
    <svg
        class="cnm-icon"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19 4.8667H5C3.89543 4.8667 3 5.76213 3 6.8667V20.8667C3 21.9713 3.89543 22.8667 5 22.8667H19C20.1046 22.8667 21 21.9713 21 20.8667V6.8667C21 5.76213 20.1046 4.8667 19 4.8667Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M16 2.8667V6.8667"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8 2.8667V6.8667"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3 10.8667H21"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
