import { defineStore } from 'pinia';

import { useInitLocalesByRol } from '@/composables/common/global/useInitLocalesByRol';
import { getUserFromToken } from '@/services/utils/authToken';

import { useAuthenticationStore } from '@/store/common/authentication';
import { useLocaleStore } from '@/store/locales';
import { useSeasonStore } from '@/store/seasons';
import { useCMSStore } from '@/store/cms';

import LoginApiService from '@/services/LoginApiService';
import CmsApiService from '@/services/CmsApiService';

const DEFAULT_CURRRENCY = 'EUR';

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
        agentSelected: null
    }),

    getters: {
        isLogged: state => state.user !== null,
        getCustomerId: state => state.user?.customerId,
        getAgentId: state => {
            if (state.user?.roles.includes('ROLE_SUPERAGENT')) {
                return state.agentSelected?.id;
            }

            return state.user?.agentId;
        },
        isAgent: state => {
            return state.user?.roles.some(role => ['ROLE_SUPERAGENT', 'ROLE_AGENT', 'ROLE_SUBAGENT'].includes(role));
        },
        isSubAgent: state => {
            return state.user?.roles.includes('ROLE_SUBAGENT') || state.agentSelected?.isSubagent;
        },
        isSuperAgent: state => state.user?.roles.includes('ROLE_SUPERAGENT'),
        isCustomer: state => state.user?.roles.includes('ROLE_CUSTOMER'),
        isCMS: state => state.user?.roles.includes('ROLE_CMS_USER'),
        hasCart: state => state.user?.cartId !== null,
        groupPrice: state => {
            if (state.user?.roles.includes('ROLE_SUPERAGENT')) {
                return state.agentSelected?.priceGroupId;
            }

            return state.user?.priceGroupId || null;
        },
        getCurrency: state => {
            if (state.user?.roles.includes('ROLE_SUPERAGENT')) {
                return state.agentSelected?.currency.id;
            }

            return state.user?.currency?.id || DEFAULT_CURRRENCY;
        },
        getUserRoles: state => state.user?.roles || null, // ROLE_SUPERAGENT, ROLE_AGENT, ROLE_SUBAGENT, ROLE_CUSTOMER, ROLE_CMS
        getDisplayName: state => {
            if (state.isSuperAgent) {
                return state.agentSelected?.name;
            }

            return state.user?.displayName || null;
        },
        getUser: state => state.user || null
    },

    actions: {
        /**
         * Login the user
         * @param {Object} user
         *
         * @returns {Promise}
         *
         */
        login(user) {
            const authenticationStore = useAuthenticationStore();

            const payload = {
                email: user.email,
                password: user.password
            };

            return new Promise((resolve, reject) => {
                CmsApiService.login(payload)
                    .then(response => {
                        const { token, ...userInfo } = response.data;
                        authenticationStore.setToken(token);
                        const user = getUserFromToken(token);

                        this.setUser({
                            ...userInfo,
                            email: user.username,
                            cartId: userInfo.cartId ?? null
                        });

                        if (this.isCMS) {
                            const cmsStore = useCMSStore();
                            cmsStore.saveCmsJwtToken(userInfo.token_cms);
                        }

                        const seasonStore = useSeasonStore();
                        seasonStore.clearSeasons();
                        resolve(this.user);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        /**
         * Logout the user
         *
         */
        logout() {
            useAuthenticationStore().logout();
            localStorage.clear();
            this.user = null;
            this.agentSelected = null;
        },

        /**
         * Set the user info
         * @param {Object} userInfo
         *
         * @returns {Promise}
         */
        setUser(userInfo) {
            localStorage.removeItem('cnm-user');
            this.user = userInfo;
            localStorage.setItem('cnm-user', JSON.stringify(userInfo));
            useInitLocalesByRol();
        },

        /**
         * @param {Number} cartId
         */
        setCartId(cartId) {
            this.user.cartId = cartId;
            localStorage.setItem('cnm-user', JSON.stringify(this.user));
        },

        /**
         * Load the app info & settings for the current user
         */
        loadInfo() {
            const seasonStore = useSeasonStore();

            const user = JSON.parse(localStorage.getItem('cnm-user')) || null;
            if (user) {
                this.setUser(user);
            }

            if (!seasonStore.getSeasons?.length) {
                seasonStore.loadSeasons();
            }
            const agentSelected = JSON.parse(localStorage.getItem('cnm-agent-selected')) || null;
            if (agentSelected) {
                this.setAgentSelected(agentSelected);
            }

            const localeSelected = localStorage.getItem('cnm-locale') || 'es';
            if (localeSelected) {
                useLocaleStore().setLocale(localeSelected);
            }
        },

        /**
         * Load the global app locales
         */
        loadLocales() {
            const localeStore = useLocaleStore();
            const locales = JSON.parse(localStorage.getItem('cnm-locales')) || [];
            if (!locales.length) {
                localeStore.loadLocales();
            } else {
                localeStore.setLocales(locales);
            }
        },

        /**
         * Register a new user
         * @param {Object} user
         *
         * @returns {Promise}
         */
        register({ nif, code, email }) {
            const localeStore = useLocaleStore();
            const locale = localeStore.getLocale;
            const payload = {
                email: email,
                supervisorTin: nif,
                supervisorCustomerCode: code,
                locale: locale
            };

            return new Promise((resolve, reject) => {
                LoginApiService.register(payload)
                    .then(() => {
                        resolve();
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * Reset the user's password
         * @param {String} password
         *
         * @returns {Promise}
         */
        setPassword(password) {
            const payload = {
                password: password.password,
                passwordToken: password.passwordToken
            };

            return new Promise((resolve, reject) => {
                LoginApiService.setPassword(payload)
                    .then(() => {
                        resolve();
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * Change the user's password (logged in)
         * @param {String} password
         *
         * @returns {Promise}
         */
        changePassword(password) {
            const payload = {
                currentPassword: password.password,
                newPassword: password.newPassword
            };

            return new Promise((resolve, reject) => {
                LoginApiService.changePassword(payload)
                    .then(() => {
                        resolve();
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * Send an email to the user with a link to reset the password
         *
         * @param {String} email
         *
         * @returns {Promise}
         */
        forgotPassword(email) {
            const payload = {
                email: email
            };

            return new Promise((resolve, reject) => {
                LoginApiService.forgotPassword(payload)
                    .then(() => {
                        resolve();
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * Confirm the registration of a new user
         *
         * @param {String} passwordToken
         *
         * @returns {Promise}
         */
        approveSignupRequest(passwordToken) {
            const payload = {
                passwordToken: passwordToken
            };

            return new Promise((resolve, reject) => {
                LoginApiService.approveSignupRequest(payload)
                    .then(() => {
                        resolve();
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * @param {Object} agentID
         */
        setAgentSelected(agent) {
            this.agentSelected = agent;
            localStorage.setItem('cnm-agent-selected', JSON.stringify(agent));
        },

        clearAgentSelected() {
            this.agentSelected = null;
            localStorage.removeItem('cnm-agent-selected');
        }
    }
});
