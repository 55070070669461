<template>
    <AuthLayout>
        <template #auth-content>
            <v-card
                class="pa-10 pt-0"
                elevation="0"
            >
                <v-card-text class="px-0 pt-10 pb-5">
                    <span class="body-medium text-dark d-block mb-4 text-center">{{ $t('auth.approveUser') }}</span>
                </v-card-text>
                <v-card-actions class="pa-0">
                    <Button
                        class="w-100 text-h4"
                        :text="$t('common.action.confirm')"
                        :loading="loading"
                        @click="approveUser"
                    />
                </v-card-actions>
            </v-card>
        </template>
    </AuthLayout>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useNotificationStore } from '@/store/common/notification';
import { useUserStore } from '@/store/user';

import AuthLayout from '@/layouts/auth/AuthLayout.vue';
import Button from '@/components/Common/Button.vue';

const { loading, approveUser } = useApproveUser();

/**
 * Reusable functions specific to this component
 */
function useApproveUser() {
    const loading = ref(false);

    // Global
    const notificationStore = useNotificationStore();

    // External
    const { t } = useI18n();

    const approveUser = async () => {
        loading.value = true;
        const passwordTokenFromUrl = window.location.search.split('token=')[1];

        // If no passwordToken is provided, return
        if (!passwordTokenFromUrl) {
            loading.value = false;
            notificationStore.error({ message: t('auth.errors.invalidLink') });

            return;
        }

        try {
            const userStore = useUserStore();
            await userStore.approveSignupRequest(passwordTokenFromUrl);
            notificationStore.success({ message: t('auth.success.approveUser') });
        } catch (error) {
            const code = error?.response?.data?.code;
            notificationStore.error({ message: t('auth.errors.invalidLink', { code }) });
        } finally {
            loading.value = false;
        }
    };

    return {
        loading,
        approveUser
    };
}
</script>
