import ApiService from '@/plugins/axiosCnm';

class SeasonsApiService {
    /**
     * Get the seasons
     *
     * @return {Promise}
     */
    getSeasons(signal = null) {
        return ApiService.get('/seasons', { signal });
    }
}

export default new SeasonsApiService();
