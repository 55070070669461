const tap = {
    mounted: (el, binding) => {
        const longPressTime = 500;
        let startTime = 0;
        let tapTimeout;

        el.ontouchstart = event => {
            event.preventDefault();
            startTime = new Date();
            tapTimeout = setTimeout(() => {
                if (typeof binding.value != 'undefined') {
                    binding.value.quantity = 0;
                    binding.value.inCartQuantity = 0;
                }
            }, longPressTime);
        };

        el.ontouchend = event => {
            event.preventDefault();
            clearTimeout(tapTimeout);
            if (startTime === 0) {
                return false;
            }
            const endTime = new Date();
            const touchTime = endTime.getTime() - startTime.getTime();
            if (touchTime < longPressTime) {
                if (typeof binding.value != 'undefined') {
                    binding.value.quantity++;
                    binding.value.inCartQuantity++;
                }
            }
        };

        el.ontouchmove = event => {
            event.preventDefault();
            startTime = 0;
            clearTimeout(tapTimeout);
        };
    }
};

const swipe = {
    mounted: (el, binding) => {
        let lastPos;
        const axis = binding.arg == 'h' ? 'x' : 'y';
        const div = axis == 'x' ? 35 : 32;

        el.ontouchstart = event => {
            event.preventDefault();
            lastPos = getCoords(event);
        };
        el.ontouchmove = event => {
            event.preventDefault();
            const shift = (lastPos[axis] - getCoords(event)[axis]) / div;
            lastPos = getCoords(event);

            binding.value.quantity = binding.value.quantity + shift;
            binding.value.inCartQuantity = binding.value.inCartQuantity + shift;
        };
        el.ontouchend = event => {
            event.preventDefault();
            binding.value.quantity = Math.round(binding.value.quantity);
            binding.value.inCartQuantity = Math.round(binding.value.inCartQuantity);
        };
    }
};

const tapAndSwipe = {
    mounted: (el, binding) => {
        const longPressTime = 500;

        let startTime = 0;
        let lastPos;
        let tapTimeout;

        const axis = binding.arg == 'h' ? 'x' : 'y';
        const div = axis == 'x' ? 35 : 32;

        el.ontouchstart = event => {
            event.preventDefault();
            lastPos = getCoords(event);
            startTime = new Date();
            tapTimeout = setTimeout(() => {
                if (typeof binding.value != 'undefined') {
                    binding.value.quantity = 0;
                    binding.value.inCartQuantity = 0;
                }
            }, longPressTime);
        };
        el.ontouchmove = event => {
            event.preventDefault();
            const shift = (lastPos[axis] - getCoords(event)[axis]) / div;
            lastPos = getCoords(event);

            binding.value.quantity = binding.value.quantity + shift;
            binding.value.inCartQuantity = binding.value.inCartQuantity + shift;

            startTime = 0;
            clearTimeout(tapTimeout);
        };
        el.ontouchend = event => {
            event.preventDefault();
            binding.value.quantity = Math.round(binding.value.quantity);
            binding.value.inCartQuantity = Math.round(binding.value.inCartQuantity);

            clearTimeout(tapTimeout);
            if (startTime === 0) {
                return false;
            }
            const endTime = new Date();
            const touchTime = endTime.getTime() - startTime.getTime();
            if (touchTime < longPressTime) {
                if (typeof binding.value != 'undefined') {
                    binding.value.quantity++;
                    binding.value.inCartQuantity++;
                }
            }
        };
    }
};

export { tap, swipe, tapAndSwipe };

function getCoords(event) {
    var touch = event.touches[0];

    return { x: touch.pageX, y: touch.pageY };
}
