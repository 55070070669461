import ApiService from '@/plugins/axiosCnm';

class SelectionsApiServices {
    /**
     * Get the selections
     *
     * @return {Promise}
     */
    getSelections() {
        return ApiService.get('/selections');
    }

    /**
     * Create selection
     *
     * @param {Object} selection with the name
     *
     * @return {Promise}
     */
    createSelection(selection) {
        return ApiService.post('/selections', selection);
    }

    /**
     * Update selection
     *
     * @param {String|Number} selectionId
     * @param {Object} selection with the name
     *
     * @return {Promise}
     */
    updateSelection(selectionId, selection) {
        return ApiService.put(`/selections/${selectionId}`, selection);
    }

    /**
     * Delete selections
     *
     * @param {Array} selectionIds
     *
     * @return {Promise}
     */
    deleteSelections(selectionIds) {
        return ApiService.delete('/selections', {
            data: selectionIds
        });
    }

    // *** For Single selection **

    /**
     * Get selection by Id
     *
     * @param {String|Number} selectionId
     *
     * @return {Promise}
     */
    getSelection(selectionId) {
        return ApiService.get(`/selections/${selectionId}`);
    }

    /**
     * Get selection Products
     *
     * @param {String|Number} selectionId
     * @param {Object} params
     *
     * @return {Promise}
     */
    getSelectionProducts(selectionId, params = {}) {
        return ApiService.get(`/selections/${selectionId}/products`, {
            params: params
        });
    }

    /**
     * @param {Array} productColorIds String with value divided by - character (reference-colorId), Example: "102705-20"
     * @param {String|Number} selectionId
     *
     * @return {Promise}
     */
    removeProductsFromSelection(selectionId, productColorIds) {
        const productColorIdsToSend = productColorIds.map(product => {
            const [reference, colorId] = product.split('-');

            return { reference, colorId };
        });

        return ApiService.delete(`/selections/${selectionId}/products`, {
            data: { items: productColorIdsToSend }
        });
    }

    /**
     * @param {String|Number} selectionId
     * @param {String} productReference
     * @param {String} colorId
     *
     * @return {Promise}
     */
    addProductToSelection(selectionId, products) {
        // return ApiService.post(`/selections/${selectionId}/products`, productColorId);
        return ApiService.post(`/selections/${selectionId}`, products);
    }

    /**
     * Sync the Product (reference-colorId) with the selections (The previous relationship is omitted)
     *
     * @param {String} productReference
     * @param {String} colorId
     * @param {Array<String|Number>} selectionIds
     *
     * @return {Promise}
     */
    syncProductToSelections(productReference, colorId, selectionsId) {
        const productColorId = `${productReference}-${colorId}`;

        return ApiService.post(`/products/${productColorId}/selections`, selectionsId);
    }

    /**
     * @param {String|Number} selectionId
     *
     * @return {Promise}
     */
    convertSelectionToCart(selectionId, priceGroupId) {
        return ApiService.post(`/selections/${selectionId}/to-cart?priceGroupId=${priceGroupId}`);
    }

    /**
     * @param { Object } payload
     * @param { String } payload.selectionId
     * @param { String } payload.locale
     * @param { String } payload.priceGroup
     * @param { Array } payload.emailAddresses
     *
     * @return {Promise}
     */
    sendSelectionByEmail({ selectionId, locale, emailAddresses, priceGroup }) {
        return ApiService.post(`/selections/${selectionId}/email?priceGroupId=${priceGroup}`, { locale, emails: emailAddresses });
    }
}

export default new SelectionsApiServices();
