export default {
    findItemById,
    arrayColumn
};

/**
 * Get the unique valid values of a property from an array of objects
 * Like a JS version of array_unique(array_column())
 *
 * @param {Array} items
 * @param {String} name
 * @param {Boolean} withBlanks
 *
 * @return {Array}
 */
function arrayColumn(items, name, withBlanks = false) {
    let array = items.map((elem) => elem[name]);
    // Flatten the array one level in case we have any bidimensional elements
    array = array.flat(1);
    // Return the array of unique elements (excluding 0, false, null and undefined unless specified)
    return array.filter((item, index) => array.indexOf(item) === index && (item || withBlanks));
}

/**
* Find an item by its id in an array of objects
*
* @param {Array} items
* @param {Number} id
*
* @return {Array|undefined}
*/
function findItemById(items, id) {
    return items.find(elem => elem.id == id);
}