<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M4.70801 8C4.70801 8 7.2879 12.61 11.8027 12.61C16.3175 12.61 18.8974 8 18.8974 8"
            stroke="black"
            stroke-width="2"
            stroke-linecap="square"
            stroke-linejoin="round"
        />
        <path
            d="M19.3067 9.25897C18.8975 8.88813 18.2651 8.91927 17.8942 9.32853C17.5234 9.73779 17.5545 10.3702 17.9638 10.741L19.3067 9.25897ZM20.3287 12.8839L21.0697 13.5554L22.4127 12.0734L21.6716 11.4019L20.3287 12.8839ZM17.9638 10.741L20.3287 12.8839L21.6716 11.4019L19.3067 9.25897L17.9638 10.741Z"
            fill="black"
        />
        <path
            d="M4.69376 9.22015C5.10302 8.84931 5.73542 8.88045 6.10626 9.28971C6.47711 9.69897 6.44597 10.3314 6.03671 10.7022L4.69376 9.22015ZM3.6718 12.8451L2.93077 13.5166L1.58783 12.0345L2.32886 11.3631L3.6718 12.8451ZM6.03671 10.7022L3.6718 12.8451L2.32886 11.3631L4.69376 9.22015L6.03671 10.7022Z"
            fill="black"
        />
        <path
            d="M8.25227 12.6333C8.45479 12.1195 9.03549 11.8671 9.5493 12.0697C10.0631 12.2722 10.3155 12.8529 10.113 13.3667L8.25227 12.6333ZM8.93051 16.3668L8.56383 17.2971L6.70314 16.5637L7.06982 15.6334L8.93051 16.3668ZM10.113 13.3667L8.93051 16.3668L7.06982 15.6334L8.25227 12.6333L10.113 13.3667Z"
            fill="black"
        />
        <path
            d="M15.9303 12.6333C15.7278 12.1195 15.1471 11.8671 14.6333 12.0697C14.1195 12.2722 13.8671 12.8529 14.0697 13.3667L15.9303 12.6333ZM15.2521 16.3668L15.6188 17.2971L17.4795 16.5637L17.1128 15.6334L15.2521 16.3668ZM14.0697 13.3667L15.2521 16.3668L17.1128 15.6334L15.9303 12.6333L14.0697 13.3667Z"
            fill="black"
        />
    </svg>
</template>
