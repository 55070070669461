import { defineStore } from 'pinia';

import { i18n } from '@/plugins/lang/i18n';
import { useUserStore } from '@/store/user';
import { useLoadingStore } from '@/store/common/loading';
import { useNotificationStore } from '@/store/common/notification';

import CustomerApiServices from '@/services/CustomerApiServices';
import { newCustomerTransformer } from '@/services/transformers/NewCustomer.transformer';
import { HTTP_CREATED } from '@/data/common/responseStatus';

function getDefaultState() {
    return {
        customer: {
            id: null,
            code: null,
            name: null,
            tradeName: null,
            contact: null,
            email: null,
            phoneNumber: null,
            billingAddress: {
                tin: null,
                name: null,
                street: null,
                city: null,
                zip: null,
                region: null,
                countryId: null
            },
            paymentMethod: {
                id: null,
                description: null
            }
        },
        customerAddresses: [],
        customerGroups: [],
        customerTemplates: [],
        customers: [],
        paymentMethods: [],
        paymentTerms: []
    };
}

export const useCustomerStore = defineStore('customer', {
    state: getDefaultState,

    getters: {
        /**
         * @returns {Object}
         */
        getCustomer: state => state.customer,

        /**
         * @returns {Array}
         */
        getCustomers: state => state.customers,

        /**
         * @returns {Array}
         */
        getCustomerAddresses: state => state.customerAddresses,

        /**
         * @returns {Array}
         */
        getCustomerGroups: state => state.customerGroups,

        /**
         * @returns {Array}
         */
        getCustomerTemplates: state => state.customerTemplates,

        /**
         * @returns {Array}
         */
        getPaymentMethods: state => state.paymentMethods,

        /**
         * @returns {Array}
         */
        getPaymentTerms: state => state.paymentTerms
    },

    actions: {
        /**
         * Reset state
         *
         * @param {Object} state
         */
        resetState() {
            const newState = getDefaultState();
            Object.keys(newState).forEach(key => {
                this.$state[key] = newState[key];
            });
        },

        /**
         * @param {Boolean} forceLoadIfExist - allow to avoid load the costumer if was loaded previously
         */
        async loadCustomer(customerId, forceLoadIfExist = true) {
            if (forceLoadIfExist || !this.customer.id) {
                const response = await CustomerApiServices.getCustomer(customerId);
                this.customer = response.data;
            }
        },

        /**
         * @param {Boolean} forceLoadIfExist - allow to avoid load if was loaded previously
         */
        async loadCustomerAddresses(customerId, forceLoadIfExist = true) {
            if (forceLoadIfExist || !this.customerAddresses?.length) {
                const response = await CustomerApiServices.getCustomerAddresses(customerId);
                this.customerAddresses = response.data;
            }
        },

        /**
         * @param {Boolean} forceLoadIfExist - allow to avoid load if was loaded previously
         */
        async loadCustomerGroups(forceLoadIfExist = true) {
            if (forceLoadIfExist || !this.customerGroups?.length) {
                const response = await CustomerApiServices.getCustomerGroups();
                this.customerGroups = response.data;
            }
        },

        /**
         * @param {Boolean} forceLoadIfExist - allow to avoid load if was loaded previously
         */
        async loadCustomerTemplates(forceLoadIfExist = true) {
            if (forceLoadIfExist || !this.customerTemplates?.length) {
                const response = await CustomerApiServices.getCustomerTemplates();
                this.customerTemplates = response.data;
            }
        },

        async setCustomers(customers) {
            this.customers = customers;
        },

        async loadCustomersPaginated(payload) {
            const useUser = useUserStore();
            const { getAgentId: agentId } = useUser;

            try {
                return new Promise((resolve, reject) => {
                    CustomerApiServices.getCustomers(payload, agentId)
                        .then(response => {
                            resolve(response.data);
                        })
                        .catch(err => reject(err));
                });
            } catch (ex) {
                const notificationStore = useNotificationStore();
                notificationStore.error({ message: i18n.t('common.notification.read.error') });
            }
        },

        /**
         * @param {Boolean} forceLoadIfExist - allow to avoid load if was loaded previously
         */
        async loadPaymentMethods({ forceLoadIfExist = true, subagentId }) {
            if (forceLoadIfExist || !this.paymentMethods?.length) {
                const response = await CustomerApiServices.getPaymentMethods(subagentId);
                this.paymentMethods = response.data;
            }
        },

        /**
         * @param {Boolean} forceLoadIfExist - allow to avoid load if was loaded previously
         */
        async loadPaymentTerms({ forceLoadIfExist = true, subagentId }) {
            if (forceLoadIfExist || !this.paymentTerms?.length) {
                const response = await CustomerApiServices.getPaymentTerms(subagentId);
                this.paymentTerms = response.data;
            }
        },

        /**
         * @param {String|Number} customerId
         * @param {Object} address
         */
        async createCustomerAddress(customerId, address) {
            const { setLoading } = useLoadingStore();
            const notificationStore = useNotificationStore();

            try {
                setLoading(true);
                const response = await CustomerApiServices.createCustomerAddress(customerId, address);
                if (response.status === HTTP_CREATED) {
                    notificationStore.success({ message: i18n.t('common.notification.create.success') });
                    this.customerAddresses.push(response.data);

                    return response.data;
                }
            } catch (ex) {
                notificationStore.error({ message: i18n.t('common.notification.create.error') });

                return null;
            } finally {
                setLoading(false);
            }
        },

        /**
         * @param {Object} payload // - whit the next attributes
         * @param {Object} payload.data // customer basic info
         * @param {Object} payload.billingAddress
         * @param {Object} payload.bankAccounts
         * @param {Object} payload.comments
         *
         * @return {Promise}
         */
        async createCustomer({ data, billingAddress, bankAccounts, comments }) {
            const { setLoading } = useLoadingStore();
            const notificationStore = useNotificationStore();

            try {
                setLoading(true);

                const useUser = useUserStore();
                const { agentId } = useUser.user;
                const { groupPrice: priceGroupId } = useUser;
                const customerData = newCustomerTransformer({
                    data,
                    billingAddress,
                    bankAccounts,
                    comments,
                    priceGroupId,
                    agentId
                });

                const response = await CustomerApiServices.createCustomer(customerData);
                this.customerAddresses = [];

                if (response.status === HTTP_CREATED) {
                    notificationStore.success({ message: i18n.t('common.notification.create.success') });

                    return response.data;
                }
            } catch (ex) {
                const errorMessage = ex?.response?.data?.message || i18n.t('common.notification.create.error');
                notificationStore.error({ message: errorMessage });

                return null;
            } finally {
                setLoading(false);
            }
        }
    }
});
