/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
// import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Icons
import icons from './vuetifyConfig/vuetifyIcons';

// Themes
import vuetifyTheme from './vuetifyConfig/vuetifyTheme';

// Composables
import { createVuetify } from 'vuetify';

// Locale
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

export function createVuetifyCustomized ( { i18n, useI18n } ) {

    return createVuetify({
        icons,
        theme: { ...vuetifyTheme },
        locale: {
            adapter: createVueI18nAdapter({ i18n, useI18n })
        }
    });
}
