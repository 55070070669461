import { defineStore } from 'pinia';

import { i18n } from '@/plugins/lang/i18n';
import SelectionsApiServices from '@/services/SelectionsApiServices';
import { useLoadingStore } from '@/store/common/loading';
import { useNotificationStore } from '@/store/common/notification';
import { HTTP_OK, HTTP_CREATED } from '@/data/common/responseStatus';

export const useSelectionsStore = defineStore('selections', {
    state: () => ({
        selections: []
    }),

    getters: {
        /**
         * @return {Array} selections
         */
        getSelections: state => state.selections,

        /**
         * @return {Object} selection
         */
        getSelectionById: state => {
            return selectionId => state.selections.find(selection => selection.id === selectionId);
        }
    },

    actions: {
        /**
         * Load selections
         */
        async loadSelections() {
            try {
                const response = await SelectionsApiServices.getSelections();
                this.selections = response.data;
            } catch (ex) {
                const notificationStore = useNotificationStore();
                notificationStore.error({ message: i18n.t('common.notification.read.error') });
            }
        },

        /**
         * create Selection
         *
         * @param {Object} selection with the name
         */
        async createSelection({ name }) {
            const loadingStore = useLoadingStore();
            const notificationStore = useNotificationStore();

            try {
                loadingStore.setStatus(true);
                const response = await SelectionsApiServices.createSelection({ name });
                if (response.status === HTTP_CREATED) {
                    this.selections.push(response.data);

                    return response.data;
                }
            } catch (ex) {
                notificationStore.error({ message: i18n.t('selections.notification.create.error') });
            } finally {
                loadingStore.setStatus(false);
            }
        },

        /**
         * change selection Name
         *
         * @param {string|number} selectionId
         * @param {string} newName
         */
        async changeSelectionName(selectionId, newName) {
            const loadingStore = useLoadingStore();
            const notificationStore = useNotificationStore();
            try {
                loadingStore.setStatus(true);
                const response = await SelectionsApiServices.updateSelection(selectionId, { name: newName });
                if (response.status === HTTP_OK) {
                    notificationStore.success({ message: i18n.t('common.notification.update.success') });
                    const found = this.selections.find(selection => selection.id === selectionId);
                    if (found) {
                        found.name = newName;
                    }
                }
            } catch (ex) {
                notificationStore.error({ message: i18n.t('common.notification.update.error') });
            } finally {
                loadingStore.setStatus(false);
            }
        },

        /**
         * Delete selections
         *
         * @param {Array} selectionIds
         */
        async deleteSelections(selectionIds) {
            const loadingStore = useLoadingStore();
            const notificationStore = useNotificationStore();
            try {
                loadingStore.setStatus(true);
                const response = await SelectionsApiServices.deleteSelections({ selectionIds });
                if (response.status === HTTP_OK) {
                    notificationStore.success({ message: i18n.t('common.notification.delete.success') });
                    this.selections = this.selections.filter(selection => !selectionIds.includes(selection.id));
                }
            } catch (ex) {
                notificationStore.error({ message: i18n.t('common.notification.delete.error') });
            } finally {
                loadingStore.setStatus(false);
            }
        }
    }
});
