<template>
    <h5 class="text-h5 text-center my-6">{{ props.title }}</h5>

    <v-row class="mx-0 my-4">
        <FilterToggle
            v-if="props.toggle"
            v-model="selected"
            cols="12"
            :filterItems="productsStore.filters.section"
        />

        <v-col
            v-for="section in productsStore.filters.section"
            v-else
            :key="section.id"
            cols="4"
            xl="12"
            class="pa-0"
        >
            <Checkbox
                v-model="selected"
                :label="section.name"
                :value="section.id"
            />
        </v-col>
    </v-row>
</template>

<script setup>
import { computed } from 'vue';
import { useProductsStore } from '@/store/products';
import FilterToggle from '@/components/Catalogue/Filters/Types/FilterToggle.vue';
import Checkbox from '@/components/Common/Checkbox.vue';

const productsStore = useProductsStore();

const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        required: true
    },
    toggle: {
        type: Boolean,
        default: true
    },
    delayed: {
        type: Boolean,
        default: false
    }
});

const selected = computed({
    get: () =>
        props.delayed ? productsStore.delayedFiltersSelected[props.name] : productsStore.filtersSelected[props.name],
    set: value =>
        props.delayed
            ? productsStore.addDelayedFilterSelected(props.name, value)
            : productsStore.addFilterSelected(props.name, value)
});
</script>

<style lang="scss" scoped>
:deep(.v-btn-group--divided) {
    border-color: rgba(var(--v-border-color));
    .v-btn {
        &__content {
            z-index: 1;
        }
        &--active.v-btn--variant-outlined {
            color: $grey-scale-5;
        }
        &--active .v-btn__overlay {
            background-color: $grey-scale-1;
            opacity: 1;
            z-index: 0;
        }
        &:not(:last-child) {
            border-inline-end-width: thin;
            border-inline-end-style: solid;
            border-inline-end-color: rgba(var(--v-border-color));
        }
    }
}
</style>
