<template>
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.60825 6.83331V1.33331M9.60825 6.83331H15.0333M9.60825 6.83331L15.0333 1.33331M6.75825 9.83331H1.33325M6.75825 9.83331V15.3333M6.75825 9.83331L1.33325 15.3333"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
