<template>
    <BasePromiseDialog
        :title="titleDialog"
        :message="message"
        width="28.375rem"
        :iconLeft="iconLeft"
        :closeDialog="closeDialog"
        :buttonClose="buttonClose"
        :info="info"
    >
        <template
            v-if="titleI18n?.key"
            #title
        >
            <!-- https://vue-i18n.intlify.dev/guide/advanced/component.html#slots-syntax-usage -->
            <i18n-t
                :keypath="titleI18n.key"
                tag="div"
                scope="global"
                class="body-medium"
            >
                <template
                    v-if="titleI18n.email"
                    #email
                >
                    <span class="body-bold">{{ titleI18n.email }}</span>
                </template>

                <template
                    v-if="titleI18n.textBold"
                    #textBold
                >
                    <span class="body-bold">{{ titleI18n.textBold }}</span>
                </template>
            </i18n-t>
        </template>

        <!-- default template -->
        <div
            v-if="buttonYes || buttonNo || buttonCancel"
            class="d-flex justify-end mt-12"
        >
            <Button
                v-if="buttonYes"
                :text="$t('common.yes')"
                class="px-8"
                :height="51"
                @click="closeDialog(true)"
            />
            <Button
                v-if="buttonNo"
                :text="$t('common.no')"
                class="ml-4 px-8"
                :height="51"
                bordered
                @click="closeDialog(false)"
            />
            <Button
                v-if="buttonCancel"
                :text="$t('common.action.cancel')"
                class="ml-4 px-8"
                :height="51"
                bordered
                @click="closeDialog(null)"
            />
        </div>
    </BasePromiseDialog>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { closeDialog } from 'vue3-promise-dialog';

import BasePromiseDialog from '@/components/Common/dialogs/BasePromiseDialog.vue';
import Button from '@/components/Common/Button.vue';

const props = defineProps({
    iconLeft: {
        type: Object,
        default() {
            return {
                icon: '',
                size: 'default',
                color: undefined
            };
        }
    },
    title: {
        type: String,
        default: ''
    },
    titleI18n: {
        type: Object,
        default() {
            return {
                key: ''
            };
        }
    },
    message: {
        type: String,
        require: true
    },
    info: {
        type: String,
        default: ''
    },
    buttonYes: {
        type: Boolean,
        default: true
    },
    buttonNo: {
        type: Boolean,
        default: true
    },
    buttonCancel: {
        type: Boolean,
        default: false
    },
    buttonClose: {
        type: Boolean,
        default: true
    },
    typeConfirmation: {
        type: Boolean,
        default: true
    }
});

// External
const { t } = useI18n();

// Dialog
const titleDialog = computed(() => {
    if (props.titleI18n?.key) {
        return '';
    }

    if (props.title) {
        return props.title;
    }

    return props.typeConfirmation ? t('common.dialog.confirmation') : t('common.dialog.information');
});
</script>
