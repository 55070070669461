<template>
    <AuthLayout>
        <template #auth-content>
            <v-card
                class="pa-8 pt-0"
                elevation="0"
            >
                <WelcomeAlert class="pt-10 mb-6" />

                <div class="pb-4 pt-0">
                    <v-tabs
                        v-model="tabSelected"
                        height="90"
                        align-tabs="center"
                        grow
                    >
                        <v-tab value="login">
                            {{ $t('auth.login') }}
                        </v-tab>
                        <v-tab value="register">
                            {{ $t('auth.register') }}
                        </v-tab>
                    </v-tabs>
                </div>

                <v-window
                    v-model="tabSelected"
                    :touch="false"
                >
                    <v-window-item value="login">
                        <LoginTab />
                    </v-window-item>
                    <v-window-item value="register">
                        <RegisterTab />
                    </v-window-item>
                </v-window>

                <span
                    class="d-block body-medium mt-10"
                    v-html="$t('global.welcome.contact', { email: EMAIL_B2B })"
                ></span>
            </v-card>
        </template>
    </AuthLayout>
</template>

<script setup>
import { ref } from 'vue';

import AuthLayout from '@/layouts/auth/AuthLayout.vue';
import LoginTab from '@/components/Auth/LoginTab.vue';
import RegisterTab from '@/components/Auth/RegisterTab.vue';
import WelcomeAlert from '@/components/Auth/WelcomeAlert.vue';

// Constants
const EMAIL_B2B = import.meta.env.VITE_EMAIL_B2B;

// Local state
const tabSelected = ref('login');
</script>

<style lang="scss" scoped>
:deep(.v-slide-group__content) {
    &:before {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        height: 3px;
        background: var(--grey-scale-3);
        z-index: -1;
    }
    .v-btn {
        &.v-tab--selected {
            .v-btn__content {
                font-weight: 900;
                color: var(--grey-scale-1);
            }
        }
        &__content {
            font-size: 1rem;
            color: var(--grey-scale-3);
        }
    }
    .v-tab__slider {
        height: 6px;
        color: var(--grey-scale-1);
    }
}
</style>
