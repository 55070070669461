import ApiService from '@/plugins/axiosCnm';

class ConfigsApiService {
    /**
     * Get the locales
     *
     * @return {Promise}
     */
    getLocales(signal = null) {
        return ApiService.get('/locales', { signal });
    }

    /**
     * @return {Promise}
     */
    getCountries(signal = null) {
        return ApiService.get('/countries', { signal });
    }
}

export default new ConfigsApiService();
