<template>
    <v-dialog
        :content-class="props.contentClass"
        data-testid="modal-content"
    >
        <v-card class="cms-card">
            <slot name="modal-title">
                <v-card-title class="d-flex justify-space-between align-center pa-0">
                    <h2 class="text-h3">{{ props.title }}</h2>
                    <button
                        data-testid="modal-close"
                        @click="$emit('close')"
                    >
                        <v-icon icon="close" />
                    </button>
                </v-card-title>
            </slot>

            <v-card-text class="pa-0">
                <slot name="modal-body" />
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <slot name="modal-footer" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    contentClass: {
        type: String,
        default: ''
    }
});
</script>

<style lang="scss" scoped>
.cms-dialog {
    width: 37rem;
}
.cms-card {
    padding: 3.125rem 3.75rem;
}
</style>
