import ApiService from '@/plugins/axiosCms';

class CmsApiService {
    /**
     * Log user into the CNM and CMS system
     *
     * @param {Object} user
     * @param {String} user.email
     * @param {String} user.password
     *
     * @return {Promise}
     */
    login(user) {
        return ApiService.post('auth/login', {
            email: user.email,
            password: user.password
        });
    }

    /**
     * Get home list
     *
     * @returns {Promise}
     */
    getHomeList() {
        return ApiService.get('home/list');
    }

    /**
     * Create home
     * @param {Object} title
     *
     * @returns {Promise}
     */
    createHome({ title }) {
        return ApiService.post('home/new', {
            title: title
        });
    }

    /**
     * save texts
     * @param {Object} id
     * @param {Object} texts
     *
     * @returns {Promise}
     */
    saveTexts({ id, texts }) {
        return ApiService.post(`texts/save/${id}`, texts);
    }

    /**
     * Save price groups homes
     * @param {Object} priceGroups
     *
     * @returns {Promise}
     */
    savePriceGroupsHomes({ priceGroups }) {
        return ApiService.post(`groups/save`, {
            priceGroups
        });
    }

    /**
     * Get price groups homes
     *
     * @returns {Promise}
     */
    getPriceGroupsHomes() {
        return ApiService.get(`groups/list`);
    }

    /**
     * Get all media
     */
    getMedia() {
        return ApiService.get(`media/list`);
    }

    /**
     * Upload media
     * @param {Object} file
     *
     * @returns {Promise}
     */
    uploadMedia({ file }) {
        const formData = new FormData();
        formData.append('file', file);

        return ApiService.post(`media/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    /**
     * Delete media
     *
     * @param {Object} id
     *
     * @returns {Promise}
     */
    deleteMedia({ id }) {
        return ApiService.delete(`media/delete/${id}`);
    }

    /**
     * Update home banner
     *
     * @param {Object} id
     * @param {Object} banner
     *
     * @returns {Promise}
     */
    updateHomeBanner({ id, banner }) {
        return ApiService.patch(`/home/banner/update`, {
            id,
            media: banner
        });
    }

    /**
     * Update home title
     *
     * @param {Object} id
     * @param {Object} title
     *
     * @returns {Promise}
     */
    updateHomeTitle({ id, title }) {
        return ApiService.patch(`/home/title/update`, {
            id,
            title
        });
    }

    /**
     * Get home by price group
     */
    getHomeByPriceGroup({ priceGroupId }) {
        return ApiService.get(`home/priceGroup/${priceGroupId}`);
    }
}

export default new CmsApiService();
