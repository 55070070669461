<template>
    <Modal
        v-model="isNeededRefreshToken"
        width="33.33rem"
        persistent
    >
        <template #modal-title>
            <v-card-title class="text-center pa-0">
                <h3
                    class="text-h3"
                    v-text="$t('auth.yourSessionIsExpired')"
                />
            </v-card-title>
        </template>

        <template #modal-body>
            <span class="body-medium text-dark d-block mt-4 mb-8 text-center">
                {{ $t('auth.pleaseReInsertPassword') }}
            </span>

            <v-form
                ref="form"
                v-model="isValidForm"
            >
                <label>
                    <span class="body-medium text-dark d-block mb-4">{{ $t('auth.username') }}</span>
                    <v-text-field
                        v-model="email"
                        class="d-block mb-4 body-light"
                        variant="outlined"
                        type="text"
                        density="compact"
                        disabled
                        @keydown.enter.prevent="doLogin"
                    />
                </label>

                <label>
                    <span class="body-medium text-dark d-block mb-4">{{ $t('auth.password') }}</span>
                    <v-text-field
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        class="d-block mb-4 body-light"
                        variant="outlined"
                        density="compact"
                        :rules="[$rulesHelper.required]"
                        :append-inner-icon="showPassword ? 'hidePassword' : 'showPassword'"
                        @click:append-inner="showPassword = !showPassword"
                        @keydown.enter.prevent="doLogin"
                    />
                </label>
            </v-form>

            <a
                class="body-medium text-center text-dark font-weight-bold d-block mb-4 text-decoration-none cursor-pointer"
                @click="closeModal({ goToName: 'forgot-password' })"
                v-text="$t('auth.forgotPassword')"
            />

            <span class="d-block text-center my-10">
                <span v-text="$t('auth.ifNotCurrentUserYouCan', { user: email })"></span>
                <a
                    class="body-medium text-dark font-weight-bold mb-4 text-decoration-underline cursor-pointer"
                    @click="closeModal({ goToName: 'login' })"
                    v-text="$t('auth.changeUser')"
                />
            </span>
        </template>

        <template #modal-footer>
            <Button
                :text="$t('auth.login')"
                class="w-100 text-h4"
                :height="51"
                :disabled="!isValidForm"
                :loading="loading"
                @click="doLogin()"
            />
        </template>
    </Modal>
</template>

<script setup>
import { ref, computed, nextTick, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useNotificationStore } from '@/store/common/notification';
import { useAuthenticationStore } from '@/store/common/authentication';
import { useUserStore } from '@/store/user';

import Modal from '@/components/Common/Modal.vue';
import Button from '@/components/Common/Button.vue';

const authenticationStore = useAuthenticationStore();
const { isNeededRefreshToken } = storeToRefs(authenticationStore);

// External
const { t } = useI18n();
const router = useRouter();

const { email, password, showPassword, loading, isValidForm, form, doLogin, closeModal } = loginOnlyPasswordModal();

/**
 * Reusable functions specific to this component
 */
function loginOnlyPasswordModal() {
    const userStore = useUserStore();
    const notificationStore = useNotificationStore();

    const password = ref('');
    const email = computed(() => {
        return userStore.getUser?.email;
    });

    const isValidForm = ref(false);
    const form = ref();
    const showPassword = ref(false);
    const loading = ref(false);

    const doLogin = async () => {
        if (!isValidForm.value) {
            return;
        }

        loading.value = true;

        try {
            await userStore.login({
                email: email.value,
                password: password.value
            });
        } catch (error) {
            notificationStore.error({ message: t('auth.errors.login') });
        } finally {
            loading.value = false;
        }
    };

    const closeModal = ({ goToName } = {}) => {
        authenticationStore.setIsNeededRefreshToken(false);

        if (goToName) {
            userStore.logout();
            router.push({ name: goToName });
        }
    };

    watch(isNeededRefreshToken, async value => {
        if (value) {
            await nextTick();
            setTimeout(() => {
                form.value?.validate(); // for activate the sign in button if password is saved and completed automatically
            }, 1000);
        }
    });

    return {
        email,
        password,
        showPassword,
        loading,
        isValidForm,
        form,
        doLogin,
        closeModal
    };
}
</script>
