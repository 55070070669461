<template>
    <v-overlay
        v-model="isLoading"
        z-index="9999"
        class="align-center justify-center"
    >
        <v-progress-circular
            indeterminate
            size="32"
        />
    </v-overlay>
</template>

<script setup>
import { computed } from 'vue';
import { useLoadingStore } from '@/store/common/loading';

const loadingStore = useLoadingStore();
const isLoading = computed({
    get() {
        return loadingStore.isLoading;
    },
    set(newValue) {
        loadingStore.setStatus(newValue);
    }
});
</script>
