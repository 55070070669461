const DATE_FORMAT = 'dd/MM/yyyy';
const DATETIME_FORMAT = 'dd/MM/yyyy HH:mm:ss';

export default {
    DATE_FORMAT,
    DATETIME_FORMAT,
    currentDateTime,
    convertToDate,
    formatDate,
    formatDateToISO
};

/**
 * Get the current time and date
 *
 * @return {String}
 */
function currentDateTime() {
    const date = new Date();

    return formatDate(date, true);
}

/**
 * Convert unix timestamp to date
 *
 * @param {Number} timestamp
 *
 * @return {String} formattedDate
 */
function convertToDate(timestamp) {
    const date = new Date(timestamp * 1000);

    return formatDate(date);
}

/**
 * Format a given date
 *
 * @param {Date} date
 * @param {Boolean} datetime
 *
 * @return {String}
 */
function formatDate(date, datetime = false) {
    if (!date) {
        return null;
    }

    // prettier-ignore
    const options = datetime ?
        {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }
        :
        {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        };

    try {
        const dateObject = date instanceof Date ? date : new Date(date);

        return new Intl.DateTimeFormat('es-ES', options).format(dateObject);
    } catch (error) {
        return null;
    }
}

/**
 * Format a date to ISO format
 *
 * @param {String} dateSelected - Date selected
 *
 * @returns {String} ISO format
 */
function formatDateToISO(dateSelected) {
    const date = new Date(dateSelected);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

    return `${year}-${month}-${day}`;
}
