<template>
    <v-row>
        <v-col cols="12">
            <v-card
                class="bg-white"
                elevated="0"
                variant="outlined"
            >
                <Accordion :title="$t('filter.sole')">
                    <Sole name="sole" />
                </Accordion>
            </v-card>
        </v-col>
        <v-col
            v-for="item in filterItems"
            :key="item.name"
        >
            <v-btn
                class="w-100 bg-white"
                variant="outlined"
                data-testid="filter-button"
                @click="openModal(item)"
            >
                <span class="body-bold text-capitalize">{{ $t(`filter.${item.name}`) }}</span>
            </v-btn>
        </v-col>
    </v-row>

    <Modal
        v-if="itemSelected.name"
        v-model="showModal"
        :title="$t(`filter.${itemSelected.name}`)"
        :width="itemSelected.width"
        @close="closeModal()"
    >
        <template #modal-body>
            <component
                :is="itemSelected.component"
                :name="itemSelected.name"
                :toggle="itemSelected.name === 'section' ? false : true"
                delayed
            />
        </template>

        <template #modal-footer>
            <Button
                :text="$t('common.action.apply')"
                class="px-8"
                :height="51"
                @click="apply"
            />
        </template>
    </Modal>
</template>

<script setup>
import { ref, shallowRef } from 'vue';
import Modal from '@/components/Common/Modal.vue';
import Button from '@/components/Common/Button.vue';
import Accordion from '@/components/Common/Accordion.vue';
import Section from '@/components/Catalogue/Filters/Section.vue';
import Type from '@/components/Catalogue/Filters/Type.vue';
import Sole from './Filters/Sole.vue';
import Category from './Filters/Category.vue';
import Size from './Filters/Size.vue';
import Availability from './Filters/Availability.vue';
import Color from './Filters/Color.vue';

import { useProductsStore } from '@/store/products';

const productsStore = useProductsStore();

const filterItems = [
    { name: 'section', width: '709', component: Section },
    { name: 'type', width: '784 ', component: Type },
    { name: 'category', width: '709', component: Category },
    { name: 'size', width: '896 ', component: Size },
    { name: 'withStock', width: '515 ', component: Availability },
    { name: 'color', width: '801', component: Color }
];

const showModal = ref(false);
const itemSelected = shallowRef({});

const openModal = item => {
    productsStore.copyFiltersToDelayedFilters();
    showModal.value = true;
    itemSelected.value = item;
};

const closeModal = () => {
    showModal.value = false;
};

function apply() {
    productsStore.copyDelayedFiltersToFilters();
    showModal.value = false;
}
</script>
