<template>
    <AuthLayout>
        <template #auth-content>
            <v-card
                class="pa-10 pt-0"
                elevation="0"
            >
                <v-card-text class="px-0 pt-10 pb-5">
                    <v-form v-model="isValidForm">
                        <label>
                            <span class="body-medium text-dark d-block mb-4">{{ $t('auth.password') }}</span>
                            <v-text-field
                                v-model="user.password"
                                class="d-block mb-4 body-light"
                                variant="outlined"
                                type="password"
                                density="compact"
                                :rules="[$rulesHelper.validPassword]"
                                :error-messages="[$rulesHelper.validPassword(user.password), $t('rules.password')]"
                            />
                        </label>
                        <label>
                            <span class="body-medium text-dark d-block mb-4">
                                {{ $t('auth.repeatPassword') }}
                            </span>
                            <v-text-field
                                v-model="user.repeatPassword"
                                class="d-block mb-4 body-light"
                                variant="outlined"
                                type="password"
                                density="compact"
                                :rules="[rules.passwordMatch]"
                            />
                        </label>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-0">
                    <Button
                        class="w-100 text-h4"
                        :text="$t('common.action.send')"
                        :disabled="!isValidForm"
                        :loading="loading"
                        @click="doResetPassword"
                    />
                </v-card-actions>
            </v-card>
        </template>
    </AuthLayout>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useNotificationStore } from '@/store/common/notification';
import { useUserStore } from '@/store/user';

import AuthLayout from '@/layouts/auth/AuthLayout.vue';
import useRulesHelper from '@/helpers/rules';
import Button from '@/components/Common/Button.vue';

const { t } = useI18n();
const { loading, doResetPassword, isValidForm, user, rules } = useResetPassword();

/**
 * Reusable functions specific to this component
 */
function useResetPassword() {
    // Global
    const notificationStore = useNotificationStore();
    const router = useRouter();

    const loading = ref(false);
    const user = ref({
        email: '',
        password: '',
        repeatPassword: ''
    });

    const rules = ref({
        passwordMatch: () => user.value.password === user.value.repeatPassword || t('auth.passwordMatch')
    });

    const doResetPassword = async () => {
        loading.value = true;
        let passwordTokenFromUrl = window.location.search.split('token=')[1];

        const userStore = useUserStore();

        // If no passwordToken is provided, return
        if (!passwordTokenFromUrl) {
            loading.value = false;
            notificationStore.error({ message: t('auth.errors.invalidLink', { code: 0 }) });

            return;
        }

        // Reset password if passwordToken is provided
        try {
            await userStore.setPassword({
                password: user.value.password,
                passwordToken: passwordTokenFromUrl
            });
            notificationStore.success({ message: t('auth.success.passwordUpdated') });
            router.push({ name: 'login' });
        } catch (error) {
            notificationStore.error({ message: t('auth.errors.login') });
        } finally {
            loading.value = false;
        }
    };

    const isValidForm = computed(() => {
        if (user.value.password !== user.value.repeatPassword) {
            return false;
        }

        if (useRulesHelper.validPassword(user.value.password) !== true) {
            return false;
        }

        if (useRulesHelper.validEmail(user.value.email) !== true) {
            return false;
        }

        return true;
    });

    return {
        loading,
        user,
        rules,
        doResetPassword,
        isValidForm
    };
}
</script>

<style lang="scss" scoped>
.body-light {
    :deep(input) {
        padding: 18px 32px;
    }
}
</style>
