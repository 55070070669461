<template>
    <AuthLayout>
        <template #auth-content>
            <v-card
                class="pa-10 pt-0"
                elevation="0"
            >
                <v-card-text class="px-0 pt-10 pb-5">
                    <v-form v-model="isValidForm">
                        <label>
                            <span class="body-medium text-dark d-block mb-4">{{ $t('auth.username') }}</span>
                            <v-text-field
                                v-model="user.email"
                                class="d-block mb-4 body-light"
                                variant="outlined"
                                type="text"
                                density="compact"
                                :rules="[$rulesHelper.required, $rulesHelper.validEmail]"
                                @keydown.enter.prevent="doForgotPassword"
                            />
                        </label>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-0">
                    <Button
                        class="w-100 text-h4"
                        :text="$t('common.action.send')"
                        :disabled="!isValidForm"
                        :loading="loading"
                        @click="doForgotPassword"
                    />
                </v-card-actions>
            </v-card>
        </template>
    </AuthLayout>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useNotificationStore } from '@/store/common/notification';
import { informHtmli18n } from '@/helpers/dialogs/commons';
import AuthLayout from '@/layouts/auth/AuthLayout.vue';
import Button from '@/components/Common/Button.vue';

import { useUserStore } from '@/store/user';

// Password
const { loading, isValidForm, user, doForgotPassword } = useForgotPassword();

/**
 * Reusable functions specific to this component
 */
function useForgotPassword() {
    // External
    const { t } = useI18n();

    // Global
    const notificationStore = useNotificationStore();

    // User
    const userStore = useUserStore();
    const user = ref({
        email: ''
    });

    // Misc
    const loading = ref(false);
    const isValidForm = ref(false);

    const doForgotPassword = async () => {
        if (!isValidForm.value) {
            return;
        }

        loading.value = true;

        try {
            const { email } = user.value;
            await userStore.forgotPassword(email);

            const i18nKey = 'auth.forgotPasswordMessage';
            const i18nConfig = { email };
            await informHtmli18n({ i18nKey, i18nConfig });
        } catch (error) {
            notificationStore.error({ message: t('common.notification.send.error') });
        } finally {
            loading.value = false;
        }
    };

    return {
        user,
        loading,
        isValidForm,
        doForgotPassword
    };
}
</script>

<style lang="scss" scoped>
.body-light {
    :deep(input) {
        padding: 18px 32px;
    }
}
</style>
