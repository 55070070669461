<template>
    <v-checkbox
        class="checkbox"
        density="compact"
        true-icon="inputChecked"
        false-icon="input"
        hide-details
    >
        <template #label>
            <span class="body-small-medium">{{ props.label }}</span>
        </template>
    </v-checkbox>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        required: true
    }
});
</script>

<style lang="scss" scoped>
.checkbox {
    :deep(.v-selection-control__wrapper) {
        margin-right: 16px;
    }
}
</style>
