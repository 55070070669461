import { defineStore } from 'pinia';

export const useImageModalStore = defineStore('imageModal', {
    state: () => ({
        show: false,
        image: ''
    }),

    getters: {
        isVisible: state => state.show,
        getImage: state => state.image
    },

    actions: {
        /**
         * Set notification status
         *
         * @param {Boolean} isVisible
         */
        setStatus(isVisible) {
            this.show = isVisible;
        },

        setImage(image) {
            this.show = true;
            this.image = image;
        },

        hideModal() {
            return this.setStatus(false);
        }
    }
});
