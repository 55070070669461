<template>
<svg class="cnm-icon" width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="10.5" y="11.3667" width="27" height="5.14286" fill="black"/>
<rect x="10.5" y="19.292" width="4.5" height="4.5" rx="2.25" fill="black"/>
<rect x="19.5" y="19.292" width="18" height="4.5" fill="black"/>
<rect x="10.5" y="26.5745" width="4.5" height="4.5" rx="2.25" fill="black"/>
<rect x="19.5" y="26.5745" width="18" height="4.5" fill="black"/>
<rect x="10.5" y="33.8569" width="4.5" height="4.5" rx="2.25" fill="black"/>
<rect x="19.5" y="33.8569" width="18" height="4.5" fill="black"/>
</svg>
</template>