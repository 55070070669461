export default {
    toFixedNumber
};

/**
 * Round a number
 *
 * @param {Number} num
 * @param {Number} digits
 *
 * @return {Number}
 */
function toFixedNumber(num, digits) {
    var pow = Math.pow(10, digits);
    return Math.round(num * pow) / pow;
}