import ApiService, { axiosWithoutRedirect as ApiServiceWithoutRedirect } from '@/plugins/axiosCnm';

class LoginAPIService {
    /**
     * Log user into the CNM system
     *
     * @param {Object} user
     *
     * @return {Promise}
     */
    login(user) {
        return ApiServiceWithoutRedirect.post('/login', user);
    }

    /**
     * Register a new user
     *
     * @param {Object} user
     *
     * @return {Promise}
     */
    register(user) {
        return ApiService.post('/signup/request', user);
    }

    /**
     * Change the user's password (logged in)
     * @param {String} currentPassword
     * @param {String} newPassword
     *
     * @return {Promise}
     */
    changePassword({ currentPassword, newPassword }) {
        return ApiService.post('/password/change', {
            currentPassword,
            newPassword
        });
    }

    /**
     * Confirm the registration of a new user or reset the password
     *
     * @param {Object} password
     *
     * @return {Promise}
     */
    setPassword({ password, passwordToken }) {
        return ApiService.post('/password/set', {
            password,
            setPasswordToken: passwordToken
        });
    }

    /**
     * Send an email to the user with a link to reset the password
     *
     * @param {Object} email
     *
     * @return {Promise}
     */
    forgotPassword(email) {
        return ApiService.post('/password/reset-request', email);
    }

    /**
     * Approve the registration of a new user
     *
     * @param {String} passwordToken
     *
     * @return {Promise}
     */
    approveSignupRequest({ passwordToken }) {
        return ApiService.post('/signup/approve', {
            token: passwordToken
        });
    }
}

export default new LoginAPIService();
