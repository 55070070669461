// Utilities
import { defineStore } from 'pinia';
import SeasonsApiService from '@/services/SeasonsApiService';

export const useSeasonStore = defineStore('seasons', {
    state: () => ({
        seasons: []
    }),

    getters: {
        getSeasons: state => state.seasons
    },

    actions: {
        /**
         * Set seasons
         *
         * @param {Object} payload
         */
        setSeasons(payload) {
            this.seasons = payload;
        },

        /**
         * Clear seasons
         */
        clearSeasons() {
            this.seasons = [];
        },

        /**
         * Load seasons
         *
         * @param {Object} payload
         *
         * @returns {Promise}
         */
        loadSeasons() {
            return new Promise((resolve, reject) => {
                SeasonsApiService.getSeasons()
                    .then(response => {
                        this.setSeasons(response.data);
                        resolve();
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * Get current season name
         *
         * @param {Number} currentSeasonId
         *
         * @returns {String}
         */
        getCurrentSeasonName(currentSeasonId) {
            return this.seasons.find(season => season.id === currentSeasonId)?.name;
        }
    }
});
