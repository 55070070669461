<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M3 11.5455C3 11.5455 6.27273 5 12 5C17.7273 5 21 11.5455 21 11.5455C21 11.5455 17.7273 18.0909 12 18.0909C6.27273 18.0909 3 11.5455 3 11.5455Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
        />
        <path
            d="M12 14C13.3556 14 14.4545 12.9011 14.4545 11.5455C14.4545 10.1899 13.3556 9.09094 12 9.09094C10.6443 9.09094 9.54541 10.1899 9.54541 11.5455C9.54541 12.9011 10.6443 14 12 14Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
