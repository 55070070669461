<template>
    <div class="text-center d-flex my-4 flex-wrap">
        <label
            v-for="color in productsStore.filters.color"
            :key="color"
            class="filter-color mb-4"
            :title="color.name"
            @click="toggleSelected(color.id)"
        >
            <span
                class="filter-color-circle d-block"
                :class="{
                    [color.name]: true,
                    active: selected.includes(color.id)
                }"
                :style="{ backgroundColor: color.rgb }"
            ></span>
            <span class="filter-color-text body-small-medium d-block">{{ color.name }}</span>
        </label>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useProductsStore } from '@/store/products';

const productsStore = useProductsStore();

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    delayed: {
        type: Boolean,
        default: false
    }
});

const selected = computed({
    get: () =>
        props.delayed ? productsStore.delayedFiltersSelected[props.name] : productsStore.filtersSelected[props.name],
    set: value =>
        props.delayed
            ? productsStore.addDelayedFilterSelected(props.name, value)
            : productsStore.addFilterSelected(props.name, value)
});

const toggleSelected = color => {
    if (selected.value.includes(color)) {
        selected.value = selected.value.filter(item => item !== color);
    } else {
        selected.value = [...selected.value, color];
    }
};
</script>

<style lang="scss" scoped>
.filter-color {
    width: calc(100% / 3);
    @media (max-width: 1279px) {
        display: flex;
        align-items: center;
        &-circle {
            margin-right: 1rem;
        }
    }
    @media (min-width: 1280px) {
        &-circle {
            margin: 0 auto;
            margin-right: auto;
            margin-bottom: 0.5rem;
        }
    }
}
.filter-color-circle {
    position: relative;
    display: block;
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
    border-radius: 100%;
    &.BLANCO,
    &.WHITE {
        border: 1px solid var(--grey-scale-3);
    }
    .filter-color:hover &::before {
        border: 2px solid $gray-300;
    }
    &.active:before {
        border: 2px solid $grey-scale-1;
    }
    &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border-radius: 100%;
    }
}
.filter-color-text {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
</style>
