<template>
    <svg
        class="cnm-icon"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.5 12.9595C1.5 12.9595 5.31818 5.32031 12 5.32031C18.6818 5.32031 22.5 12.9595 22.5 12.9595C22.5 12.9595 18.6818 20.5986 12 20.5986C5.31818 20.5986 1.5 12.9595 1.5 12.9595Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12.0013 15.8309C13.5829 15.8309 14.865 14.5484 14.865 12.9662C14.865 11.3841 13.5829 10.1016 12.0013 10.1016C10.4198 10.1016 9.1377 11.3841 9.1377 12.9662C9.1377 14.5484 10.4198 15.8309 12.0013 15.8309Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
