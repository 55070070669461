<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="cnm-icon"
    >
        <path
            d="M2.28078 10.5H21.7192L19.2192 20.5L4.78078 20.5L2.28078 10.5Z"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
        />
        <path
            d="M12.0001 4.83586L15.3543 10H8.6459L12.0001 4.83586Z"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
        />
    </svg>
</template>
