import { defineStore } from 'pinia';

import { getExpirationDate } from '@/services/utils/authToken';
import { useLoadingStore } from '@/store/common/loading';

const TIME_BEFORE_EXPIRE_TOKEN = 30000; // 30 seconds of margin for refresh token before it expires really

export const useAuthenticationStore = defineStore('authentication', {
    state: () => ({
        token: null,
        jwtExpirationDate: null,
        isNeededRefreshToken: false,
        timeoutID: null
    }),

    getters: {
        getToken: state => state.token,
        getJwtExpirationDate: state => state.jwtExpirationDate,
        /**
         * get if the loaded token is expired and need to be updated (if not have token, return false because is not expired)
         *
         * @returns {Boolean}
         */
        isTokenExpired() {
            return this.getJwtExpirationDate && this.getJwtExpirationDate - Date.now() < TIME_BEFORE_EXPIRE_TOKEN;
        }
    },

    actions: {
        /**
         * @param {string} token - The token to set
         */
        setToken(token) {
            this.token = token;
            const jwtExpirationDate = getExpirationDate({ token });
            this.setJwtExpirationDate(jwtExpirationDate);

            this.setIsNeededRefreshToken(false);

            localStorage.removeItem('cnm-jwt-token');
            localStorage.setItem('cnm-jwt-token', token);

            this.scheduleTimerForUpdateToken({ forceUpdate: true });
        },

        /**
         * Loads the token from local storage if it is not on loaded on state.
         * If the token is found, it also sets the JWT expiration date.
         */
        loadTokenIfEmpty() {
            if (!this.token) {
                this.token = localStorage.getItem('cnm-jwt-token');

                if (this.token) {
                    const jwtExpirationDate = getExpirationDate({ token: this.token });
                    this.setJwtExpirationDate(jwtExpirationDate);
                }
            }
        },

        /**
         * @param {Object} jwtExpirationDate - The jwt expiration date object
         */
        setJwtExpirationDate(jwtExpirationDate) {
            this.jwtExpirationDate = jwtExpirationDate;
        },

        /**
         *
         * @param {Boolean} isNeededRefreshToken
         */
        setIsNeededRefreshToken(isNeededRefreshToken) {
            this.isNeededRefreshToken = isNeededRefreshToken;

            if (this.isNeededRefreshToken) {
                const loadingStore = useLoadingStore();
                loadingStore.setLoading(false);
            }
        },

        /**
         * Check if the user has a valid token (token exist and is not expired)
         *
         * @returns {Boolean}
         */
        hasAValidToken() {
            this.loadTokenIfEmpty();

            return this.token && !this.isTokenExpired;
        },

        /**
         * Schedule timer to refresh current token before it expires
         *
         * @param {Object} options
         * @param {Boolean} options.forceUpdate
         */
        scheduleTimerForUpdateToken({ forceUpdate = false } = {}) {
            // if is already set the timeout for refresh token and is not forced, we don't need to set it again
            if (!forceUpdate && this.timeoutID) {
                return;
            }

            this.loadTokenIfEmpty();
            // if there is no token, we can't to schedule the refresh token
            if (!this.token) {
                return;
            }

            const timeLeft = this.getJwtExpirationDate - Date.now();

            const timeToSchedule = Math.max(timeLeft - TIME_BEFORE_EXPIRE_TOKEN, 0);

            if (this.timeoutID) {
                clearTimeout(this.timeoutID);
            }

            this.timeoutID = setTimeout(() => {
                this.setIsNeededRefreshToken(true);
            }, timeToSchedule);
        },

        /**
         * Logout the user by resetting the token, expiration date, and refresh token flag.
         * Also clears any existing timeout for token expiration.
         */
        logout() {
            this.token = null;
            this.jwtExpirationDate = null;
            this.isNeededRefreshToken = false;

            if (this.timeoutID) {
                clearTimeout(this.timeoutID);
                this.timeoutID = null;
            }
        }
    }
});
