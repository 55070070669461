<template>
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.3333 1.66669H9.78946M15.3333 1.66669V7.28713M15.3333 1.66669L9.78946 7.28713M1.33325 15.9733V10.3528M1.33325 15.9733H6.87705M1.33325 15.9733L6.87705 10.3528"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
