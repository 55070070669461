import { defineStore } from 'pinia';

import i18n from '@/plugins/lang/i18n';
import SelectionsApiServices from '@/services/SelectionsApiServices';
import { useNotificationStore } from '@/store/common/notification';
import { useProductsStore } from './products';
import { useUserStore } from './user';
import { HTTP_OK, HTTP_CREATED } from '@/data/common/responseStatus';

export const useSelectionStore = defineStore('selection', {
    state: () => ({
        selection: {
            id: null,
            name: null
        }
    }),

    getters: {
        /**
         * @returns {String} selection name
         */
        getSelectionName(state) {
            return state.selection.name ?? '';
        },

        /**
         * @returns {String} selection id
         */
        getSelectionId(state) {
            return state.selection.id ?? '';
        }
    },

    actions: {
        /**
         * set selection
         *
         * @param {Object} {selectionId, name}
         */
        setCurrentSelection({ selectionId, name }) {
            this.selection = { id: selectionId, name };
        },

        /**
         * get selection products
         *
         * @return {Promise}
         */
        async getProducts() {
            const notificationStore = useNotificationStore();
            const productsStore = useProductsStore();
            const userStore = useUserStore();

            const priceGroupId = userStore.groupPrice;

            try {
                const params = {
                    localeId: i18n.locale,
                    orderBy: productsStore.orderBy,
                    priceGroupId: priceGroupId
                };

                const response = await SelectionsApiServices.getSelectionProducts(this.selection.id, params);
                if (response.status === HTTP_OK) {
                    return response.data;
                }
            } catch (ex) {
                notificationStore.error({ message: i18n.global.t('common.notification.read.error') });
            }

            return [];
        },

        /**
         * @param {String} productColorId  String with value divided by - character (reference-colorId), Example: "102705-20"
         * @param {String|Number} selectionId
         *
         * @return {Promise}
         */
        async removeProductsFromSelection(productColorIds, selectionId = null) {
            try {
                const selectionIdOrDefaultValue = selectionId ?? this.selection.id;
                const response = await SelectionsApiServices.removeProductsFromSelection(
                    selectionIdOrDefaultValue,
                    productColorIds
                );
                if (response.status === HTTP_OK) {
                    return true;
                }
            } catch (ex) {
                const notificationStore = useNotificationStore();
                notificationStore.error({
                    message: i18n.global.t('selections.notification.addProduct.error')
                });

                return false;
            }
        },

        /**
         * @param {String|Number} selectionId
         * @param {String} reference
         * @param {String} colorId
         *
         * @return {Promise}
         */
        async addProductToSelection(selectionId, reference, colorId) {
            const notificationStore = useNotificationStore();
            try {
                const response = await SelectionsApiServices.addProductToSelection(selectionId, {
                    items: [{ reference, colorId }]
                });
                if (response.status === HTTP_CREATED) {
                    return true;
                }
            } catch (ex) {
                notificationStore.error({
                    message: i18n.global.t('selections.notification.addProduct.error')
                });

                return false;
            }
        },

        /**
         * @param {String|Number|?} selectionId
         *
         * @return {Promise}
         */
        async convertSelectionToCart(selectionId = null) {
            const userStore = useUserStore();

            const priceGroupId = userStore.groupPrice;
            const selectionIdOrDefaultValue = selectionId ?? this.selection.id;
            try {
                const response = await SelectionsApiServices.convertSelectionToCart(
                    selectionIdOrDefaultValue,
                    priceGroupId
                );
                if (response.status === HTTP_OK) {
                    return response.data;
                }
            } catch (ex) {
                const notificationStore = useNotificationStore();
                notificationStore.error({
                    message: i18n.global.t('common.notification.send.error')
                });

                return false;
            }
        },

        async sendSelectionByEmail({ selectionId, locale, emailAddresses, priceGroup }) {
            const notificationStore = useNotificationStore();
            try {
                await SelectionsApiServices.sendSelectionByEmail({
                    selectionId,
                    locale,
                    emailAddresses,
                    priceGroup
                });

                notificationStore.success({
                    message: i18n.global.t('selections.notification.emailSent.success')
                });

                return true;
            } catch (ex) {
                notificationStore.error({
                    message: i18n.global.t('selections.notification.emailSent.error')
                });

                return false;
            }
        }
    }
});
