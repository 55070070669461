<template>
<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
<ellipse cx="13.7945" cy="13.8668" rx="13.7143" ry="13.5848" transform="rotate(-90 13.7945 13.8668)" fill="url(#paint0_angular_2381_31)"/>
<defs>
<radialGradient id="paint0_angular_2381_31" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.7932 13.8668) scale(13.713 13.5861)">
<stop stop-color="#D75377"/>
<stop offset="0.161458" stop-color="#F09854"/>
<stop offset="0.338542" stop-color="#F3DE89"/>
<stop offset="0.510417" stop-color="#A9C28A"/>
<stop offset="0.671875" stop-color="#408ECB"/>
<stop offset="0.833333" stop-color="#9F93AC"/>
<stop offset="1" stop-color="#C68786"/>
</radialGradient>
</defs>
</svg>
</template>