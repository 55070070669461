import ApiService from '@/plugins/axiosCnm';

class ProductsApiService {
    /**
     * Get the seasons
     *
     * @param {String} priceGroupId
     * @param {Object} params
     *
     * @return {Promise}
     */
    getProducts(priceGroupId, params = {}) {
        return ApiService.get(`/price-groups/${priceGroupId}/products`, {
            params: params
        });
    }

    /**
     * Get price groups (For CMS)
     *
     * @return {Promise}
     */
    getPriceGroups() {
        return ApiService.get('/price-groups');
    }
}

export default new ProductsApiService();
