<template>
    <div
        class="base-promise-dialog"
        @click="checkClose(true)"
    >
        <div
            :class="['base-promise-dialog__body', `dialog--${position}`]"
            @click.stop="checkClose(false)"
        >
            <div class="d-flex justify-space-between">
                <div class="d-flex">
                    <v-icon
                        v-if="buttonBack"
                        icon="chevronLeftSingle"
                        size="x-small"
                        class="mr-4 mt-1 flex-shrink-0"
                        @click="closeDialog(false)"
                    />

                    <v-icon
                        v-if="iconLeft?.icon"
                        :icon="iconLeft.icon"
                        :size="iconLeft.size || 'default'"
                        :color="iconLeft.color || undefined"
                        class="mr-4 mt-1 flex-shrink-0"
                    />

                    <slot name="title">
                        <span
                            :class="'text-' + titleSize"
                            data-testid="dialog-title"
                            >{{ title }}</span
                        >
                    </slot>
                </div>

                <v-icon
                    v-if="buttonClose"
                    icon="close"
                    class="flex-shrink-0 ml-4"
                    @click="closeDialog(null)"
                />
            </div>

            <div
                v-if="text !== ''"
                class="pt-8"
            >
                <slot name="text">
                    <span :class="'text-' + textSize">{{ text }}</span>
                </slot>
            </div>
            <slot name="top">
                <span
                    v-if="message"
                    class="body-medium d-inline-block mt-4"
                    v-text="message"
                />

                <span
                    v-if="info"
                    class="text-info body-medium d-inline-block mt-4"
                    v-text="info"
                />
            </slot>
            <div class="d-block">
                <slot />
            </div>
            <div
                v-if="$slots.bottom"
                class="base-promise-dialog__body__bottom"
            >
                <slot name="bottom" />
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    titleSize: {
        type: String,
        default: 'h4'
    },
    text: {
        type: String,
        default: ''
    },
    info: {
        type: String,
        default: ''
    },
    textSize: {
        type: String,
        default: 'h6'
    },
    message: {
        type: String,
        required: false
    },
    closeDialog: {
        type: Function,
        required: true
    },
    width: {
        type: String,
        default: '34.5625rem'
    },
    buttonBack: {
        type: Boolean,
        default: false
    },
    iconLeft: {
        type: Object,
        default() {
            return {
                icon: '',
                size: 'default',
                color: undefined
            };
        }
    },
    position: {
        type: String,
        default: 'top'
    },
    buttonClose: {
        type: Boolean,
        default: true
    }
});

const checkClose = check => {
    if (check && !props.buttonClose) {
        props.closeDialog(null);
    }
};
</script>

<style lang="scss" scoped>
.base-promise-dialog {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $background-color-modal-outer;
    z-index: $z-index-dialog;

    &__body {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $background-color-modal;
        padding: rem(40px);
        width: v-bind('props.width');
        max-height: 90vh;
        overflow-y: auto;

        &.dialog--top {
            top: rem(142px);
            transform: translateX(-50%);
        }

        &__bottom {
            position: absolute;
            bottom: rem(40px);
            width: calc(100% - rem(80px));
            display: flex;
            justify-content: space-between;
            row-gap: rem(20px);
        }
    }
}

.dialog-enter-active,
.dialog-leave-active {
    &.base-promise-dialog {
        transition: all 0.5s ease-in-out;
        opacity: 1;

        .base-promise-dialog__body:not(.dialog--center) {
            transition: all 0.2s ease-in-out;
            transform: translateX(-50%) scale(1) !important;
        }
    }
}

.dialog-enter-from,
.dialog-leave-to {
    &.base-promise-dialog {
        opacity: 0;

        .base-promise-dialog__body:not(.dialog--center) {
            transform: translateX(-50%) scale(0.8) !important;
        }
    }
}

.text-info {
    font-style: italic;
}
</style>
