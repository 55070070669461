<template>
    <BasePromiseDialog
        :title="title || $t('selections.dialog.saveTo')"
        :closeDialog="closeDialog"
        width="32.4375rem"
    >
        <div class="add-selection-dialog__center mt-10">
            <Button
                :text="$t('selections.btnNew')"
                class="px-8"
                :height="51"
                data-testid="new-selection"
                @click="closeDialog(false)"
            />
        </div>

        <v-list
            v-model:selected="selectedItems"
            class="mt-6"
            :select-strategy="'leaf'"
            max-height="50vh"
            data-testid="selections-list"
            @click:select="updateSelection($event.id, $event.value)"
        >
            <template
                v-for="item in [...items].reverse()"
                :key="item.id"
            >
                <v-list-item
                    :value="item"
                    active-color="primary"
                    class="px-0"
                >
                    <template #prepend>
                        <div :class="['position-relative', { 'box--selected': isSelectedItem(item.id) }]">
                            <v-img
                                :src="item.imageUrls.length ? item.imageUrls[0] : DEFAULT_IMAGE"
                                aspect-ratio="1"
                                width="4rem"
                            ></v-img>
                            <v-icon
                                class="icon--selected"
                                icon="check"
                            />
                        </div>
                    </template>

                    <v-list-item-title class="ml-10 body-bold">
                        {{ item.name }}
                    </v-list-item-title>
                </v-list-item>

                <v-divider class="my-3"></v-divider>
            </template>
        </v-list>
    </BasePromiseDialog>
</template>

<script setup>
import { ref, toRef } from 'vue';
import { closeDialog } from 'vue3-promise-dialog';
import { useI18n } from 'vue-i18n';

import { useNotificationStore } from '@/store/common/notification';
import BasePromiseDialog from '@/components/Common/dialogs/BasePromiseDialog.vue';

import Button from '@/components/Common/Button.vue';

const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    items: {
        type: Array,
        required: true
    },
    selectedItemIds: {
        type: Array
    },
    updateSelectionCallback: {
        type: Function
    }
});

// Misc
const DEFAULT_IMAGE = '/images/selection/selection-default.svg';

// Items selections
const { selectedItems, isSelectedItem, updateSelection } = useItemsSelection({
    selectedItemIds: toRef(props, 'selectedItemIds'),
    updateSelectionCallback: props.updateSelectionCallback
});

/**
 * Reusable functions specific to this component
 */
function useItemsSelection({ selectedItemIds, updateSelectionCallback }) {
    // External
    const { t } = useI18n();

    // Global
    const notificationStore = useNotificationStore();

    // Selection
    const defaultSelectedItems = selectedItemIds.value?.length
        ? props.items.filter(item => selectedItemIds.value.includes(item.id))
        : [];
    const selectedItems = ref(defaultSelectedItems);

    const isSelectedItem = selectionId => selectedItems.value.some(item => item.id === selectionId);

    /**
     * @param {Object} item - selection instance
     * @param {Boolean} isSelected
     */
    const updateSelection = (item, isSelected) => {
        if (updateSelectionCallback) {
            updateSelectionCallback(item, isSelected);
        }

        notificationStore.success({ message: t('common.notification.send.success') });
        closeDialog(null);
    };

    return {
        selectedItems,
        isSelectedItem,
        updateSelection
    };
}
</script>

<style lang="scss" scoped>
.add-selection-dialog__center {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: rem(20px);
}

.box--selected:before {
    width: 2rem;
    height: 2rem;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
}
</style>
