<template>
    <v-card
        class="filter-tags py-3 px-4 d-flex align-center"
        elevation="0"
    >
        <div class="d-flex align-center justify-space-between w-100">
            <div>
                <Chip
                    v-if="search?.length >= 3"
                    @click:close="productsStore.setSearch('')"
                >
                    <b class="text-capitalize">{{ $t('filter.search') }}</b>
                    : {{ search }}
                </Chip>
                <template
                    v-for="(values, name) in filtersSelected"
                    :key="name"
                >
                    <Chip
                        v-for="value in values"
                        v-show="getName(name, value)"
                        :key="`${name}_${value}`"
                        @click:close="productsStore.removeFilterSelected(name, value)"
                    >
                        <b class="text-capitalize">{{ $t(`filter.${name}`) }}</b>
                        <span v-if="name !== 'withStock'">: {{ getName(name, value) }}</span>
                    </Chip>
                </template>
            </div>

            <SemanticIcon
                :size="18"
                icon="trash"
                class="d-flex menu-icon"
                :disabled="emptyFilters"
                @click="productsStore.clearFiltersSelected()"
            />
        </div>
    </v-card>
</template>

<script setup>
import { computed } from 'vue';
import { useProductsStore } from '@/store/products';
import { storeToRefs } from 'pinia';

const productsStore = useProductsStore();
const { search, filtersSelected } = storeToRefs(productsStore);

import Chip from '@/components/Common/Chip.vue';
import SemanticIcon from '@/components/Common/SemanticIcon.vue';

const getName = (name, value) => {
    switch (name) {
        case 'section':
            return productsStore.getSectionName(value);
        case 'sole':
            return productsStore.getSoleName(value);
        case 'type':
            return productsStore.getTypeName(value);
        case 'category':
            return productsStore.getCategoryName(value);
        case 'size':
            return productsStore.getSizeName(value);
        case 'color':
            return productsStore.getColorName(value);
        default:
            return value;
    }
};

const emptyFilters = computed(() => {
    const filterValues = Object.values(filtersSelected.value);
    if (search.value?.length >= 3) {
        filterValues.push(search.value);
    }

    return filterValues.every(value => value.length === 0 || value === 0);
});
</script>

<style lang="scss" scoped>
.filter-tags {
    min-height: 58px;
}
:deep(.v-btn--disabled) {
    .v-btn__overlay {
        opacity: 0;
    }
}
</style>
