{
  "common": {},
  "global": {
    "menuLeft": {
      "selections": "Favorites"
    }
  },
  "selections": {
    "title": "Favorites",
    "btnNew": "New Favorite",
    "addNameTitle": "Name new favorite",
    "placeHolderName": "Favorite name",
    "btnMoveToCart": "Move Favorite to Cart",
    "returnToItem": "Return to favorite",
    "dialog": {
      "deleteMessage": "Are you sure to delete this Favorite? | Are you sure to remove these {count} favorites?",
      "deleteProducts": "Are you sure about removing this product from the favorite? | Are you sure to remove these {count} products-colors from the favorite?",
      "moveToCartMessage": "Are you sure to move this favorite to the basket?",
      "cartAlreadyExists": "Attention, the cart already contains products.",
      "saveTo": "Save to Favorites"
    },
    "notification": {
      "addProduct": {
        "error": "Problems occurred saving product favorite"
      },
      "removeProduct": {
        "success": "Successfully removed from favorite"
      }
    }
  }
}
