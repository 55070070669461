<template>
    <div class="d-flex align-center justify-space-between">
        <v-switch
            v-model="toggleMode"
            :width="110"
            class="catalogue-switch"
            hide-details
            inset
            false-value="model"
            true-value="color"
            false-icon="modeModel"
            false-color="blue"
            true-color="dark"
            data-testid="catalogue-swap-mode"
        />

        <slot name="center-actions" />

        <!-- v-select with the list to order items -->
        <v-select
            v-model="orderBy"
            :items="orderByItems"
            class="catalogue-select bg-white"
            menu-icon="order"
            item-title="text"
            item-value="id"
            density="compact"
            variant="outlined"
            hide-details
            text
        />

        <slot name="end-actions" />
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useProductsStore } from '@/store/products';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const productsStore = useProductsStore();
const emitUpdate = defineEmits(['update:orderBy']);

const orderBy = ref(0);
const orderByItems = [
    {
        id: 0,
        orderBy: { field: 'name', type: 'ASC' },
        text: t('filter.nameASC') // 'Nombre de la A a la Z'
    },
    {
        id: 1,
        orderBy: { field: 'name', type: 'DESC' },
        text: t('filter.nameDESC') //'Nombre de la Z a la A'
    },
    {
        id: 2,
        orderBy: { field: 'price', type: 'ASC' },
        text: t('filter.priceASC') //'Precio de menor a mayor'
    },
    {
        id: 3,
        orderBy: { field: 'price', type: 'DESC' },
        text: t('filter.priceDESC') //'Precio de mayor a menor'
    },
    {
        id: 4,
        orderBy: { field: 'id', type: 'DESC' },
        text: t('filter.skuDESC') //'Referencia más reciente'
    },
    {
        id: 5,
        orderBy: { field: 'id', type: 'ASC' },
        text: t('filter.skuASC') //'Referencia más antigua'
    }
];

const toggleMode = computed({
    get() {
        return productsStore.getMode;
    },
    set(newMode) {
        productsStore.toggleMode(newMode);
    }
});

onMounted(() => {
    productsStore.resetOrderBy();
});

/**
 * Watch for changes in the orderBy variable
 * and update the orderBy field in the store
 */
watch(orderBy, itemSelected => {
    const orderBy = orderByItems.find(item => item.id === itemSelected).orderBy;
    productsStore.setOrderBy(orderBy);
    emitUpdate('update:orderBy', orderBy);
});
</script>

<style lang="scss" scoped>
.catalogue {
    &-switch {
        max-width: fit-content;
        :deep(.v-selection-control) {
            .v-switch__track {
                background: white;
                opacity: 1;
                width: 90px;
                height: 48px;
                border-radius: 90px;
            }
            .v-switch__thumb {
                width: 42px;
                height: 42px;
            }
            .v-selection-control__input {
                background: rgb(var(--v-theme-dark));
                box-shadow: 5px 4px 12px rgba(0, 0, 0, 0.15);
                transform: translateX(-20px);
            }
            &.v-selection-control--dirty .v-selection-control__input {
                transform: translateX(20px);
                .v-switch__thumb {
                    background: url('/images/switch/multicolor.png');
                    background-size: 66%;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        :deep(.v-selection-control__input) {
            transition: 0.75s transform cubic-bezier(0.18, 0.89, 0.32, 1.28);
        }
    }
    &-select {
        max-width: 280px;
        font-weight: 650;
        font-size: 1rem;

        :deep(.v-input),
        :deep(.v-input__slot),
        :deep(.v-input__control) {
            min-height: 48px !important;
        }

        :deep(.v-field__input),
        :deep(.v-field__prepend-inner),
        :deep(.v-field__append-inner),
        :deep(.v-field__clearable) {
            padding-top: 12px;
        }
    }
}
</style>
