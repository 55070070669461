export default {
    defaultTheme: 'victoriaTheme',
    themes: {
        victoriaTheme: {
            dark: false,
            options: { customProperties: true },
            variables: {
                'high-emphasis-opacity': 1,
                'medium-emphasis-opacity': 1,
                'layout-top': 180
            },
            colors: {
                background: '#F2F2F2',
                surface: '#FFFFFF',
                primary: '#000',
                secondary: '#6c757d',

                dark: '#000000',
                white: '#FFFFFF',

                'grey-scale-2': '#444444',
                'grey-scale-3': '#B1B1B1',
                'grey-scale-4': '#CED4DA',
                'grey-scale-5': '#FFFFFF'

                // 'product-negro': '#000000',
                // 'product-marino': '#2A2F3C',
                // 'product-petroleo': '#404F60',
                // 'product-antracita': '#595C5F',
                // 'product-marron': '#45423D',

                // 'product-teja': '#AC4D30',
                // 'product-vino': '#662E32',
                // 'product-burdeos': '#7B2930',
                // 'product-whisky': '#7C5440',
                // 'product-kaki': '#6F6760',

                // 'product-jeans': '#7C8490',
                // 'product-testa': '#836859',
                // 'product-cuero': '#8F674D',
                // 'product-camel': '#926435',
                // 'product-taupe': '#987D73',

                // 'product-mostaza': '#D5A260',
                // 'product-safari': '#A8926B',
                // 'product-arena': '#BEA29C',
                // 'product-zinc': '#BCBCB8',
                // 'product-gris': '#B5B6B1',

                // 'product-beige': '#D0CAC1',
                // 'product-hielo': '#E2DFDC',
                // 'product-nude': '#C68786',
                // 'product-coralina': '#EECBB7',
                // 'product-ballet': '#D6BBB0',
            }
        }
    }
};
