<template>
    <v-card
        class="pa-6"
        elevation="0"
    >
        <h4 class="text-h4 mb-6">{{ $t('filter.filters') }}</h4>

        <Search />

        <Sole
            :title="$t('filter.sole')"
            name="sole"
        />

        <Section
            :title="$t('filter.section')"
            name="section"
        />

        <v-list
            v-model:opened="toggleStatus"
            class="filter-list"
            variant="plain"
        >
            <v-list-group
                v-for="(item, key) in filterItems"
                :key="key"
                :value="item.name"
            >
                <template #activator="activator">
                    <v-list-item
                        v-bind="activator.props"
                        :title="$t(`filter.${item.name}`)"
                        class="px-0"
                    />
                </template>

                <component
                    :is="item.component"
                    :name="item.name"
                />
            </v-list-group>
        </v-list>
    </v-card>
</template>

<script setup>
import { ref } from 'vue';
import Search from '@/components/Catalogue/Filters/Search.vue';
import Section from '@/components/Catalogue/Filters/Section.vue';
import Type from '@/components/Catalogue/Filters/Type.vue';
import Sole from './Filters/Sole.vue';
import Category from './Filters/Category.vue';
import Size from './Filters/Size.vue';
import Availability from './Filters/Availability.vue';
import Color from './Filters/Color.vue';

const toggleStatus = ref([]);
const filterItems = [
    { name: 'type', component: Type },
    { name: 'category', component: Category },
    { name: 'size', component: Size },
    { name: 'withStock', component: Availability },
    { name: 'color', component: Color },
    { name: 'sole', component: Sole }
];
</script>

<style lang="scss" scoped>
:deep(.v-list-item-title) {
    text-transform: capitalize;
}
.filter-list {
    border-top: 2px solid #ced4da;
    margin-top: 40px;

    :deep(.v-list-item--variant-plain) {
        opacity: 1;
    }
}
</style>
