import { createI18n } from 'vue-i18n';

import defaultMessages from './locales/default/index';

import numberFormats from './locales/numberFormats';

const i18nInstance = createI18n({
    legacy: false,
    locale: 'es',
    fallbackLocale: 'es',
    messages: defaultMessages(),
    numberFormats
});

export default i18nInstance;

export const i18n = i18nInstance.global;
