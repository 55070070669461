import { i18n } from '@/plugins/lang/i18n';

import defaultMessages from '@/plugins/lang/locales/default/index';
import b2bMessages from '@/plugins/lang/locales/b2b/index';

import { useUserStore } from '@/store/user';

export function useInitLocalesByRol() {
    const userStore = useUserStore();

    // Loading Messages for B2B
    if (!userStore?.isAgent) {
        /**
         * loading Rol Locales Messages
         */
        b2bMessages.forEach(lang => i18n.mergeLocaleMessage(lang.key, lang.value));
    }
    // if was merged locales for some rol previously and we need undo it (set default messages)
    else if (i18n.te('id')) {
        const messages = defaultMessages();

        Object.keys(messages).forEach(key => {
            i18n.setLocaleMessage(key, messages[key]);
        });
    }
}
