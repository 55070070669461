<template>
    <v-row class="mx-0 my-4">
        <v-col
            lg="12"
            class="pa-0"
        >
            <Checkbox
                v-model="selected"
                class="body-small-medium"
                :label="$t('filter.availabilityQuote')"
                :true-value="1"
                :false-value="0"
            />
        </v-col>
    </v-row>
</template>

<script setup>
import { computed } from 'vue';

import { useProductsStore } from '@/store/products';

import Checkbox from '@/components/Common/Checkbox.vue';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    delayed: {
        type: Boolean,
        default: false
    }
});

// Store
const productsStore = useProductsStore();

// Computed
const selected = computed({
    get: () =>
        props.delayed ? productsStore.delayedFiltersSelected[props.name] : productsStore.filtersSelected[props.name],
    set: value =>
        props.delayed
            ? productsStore.addDelayedFilterSelected(props.name, value)
            : productsStore.addFilterSelected(props.name, value)
});
</script>
