// Utilities
import { defineStore } from 'pinia';
import AgentsApiService from '@/services/AgentsApiService';

export const useAgentsStore = defineStore('agents', {
    state: () => ({
        agents: [],
        subagents: []
    }),

    getters: {
        getAgents: state => state.agents.sort((a, b) => a.name.localeCompare(b.name)),
        getSubagents: state => state.subagents
    },

    actions: {
        /**
         * @param {Object} payload
         */
        setAgents(payload) {
            this.agents = payload;
            localStorage.setItem('cnm-agents', JSON.stringify(payload));
        },

        /**
         * @param {Object} payload
         */
        setSubagents(payload) {
            this.subagents = payload;
            localStorage.setItem('cnm-subagents', JSON.stringify(payload));
        },

        /**
         * @returns {Promise}
         */
        loadAgents() {
            return new Promise((resolve, reject) => {
                AgentsApiService.getAgents()
                    .then(response => {
                        this.setAgents(response.data);
                        resolve();
                    })
                    .catch(err => reject(err));
            });
        },

        /**
         * @param {Boolean} forceLoadIfExist - allow to avoid load if was loaded previously
         */
        async loadSubagents(agentID, forceLoadIfExist = true) {
            if (forceLoadIfExist || !this.subagents?.length) {
                const response = await AgentsApiService.getSubagents(agentID);
                this.setSubagents(response.data);
                this.subagents = response.data;
            }
        }
    }
});
