<template>
    <v-row>
        <template
            v-for="product in productsStore.getProductsByMode"
            :key="getItemId(product)"
        >
            <v-col class="product-list__item">
                <div
                    :class="['position-relative', { 'box--selected': isSelectedProduct(product) }]"
                    class="product-list__item-wrapper"
                    @click="selectProduct(product)"
                >
                    <Product
                        :product="product"
                        :mode="mode"
                        @update:favorite-product="
                            updateFavoriteProduct(product.reference, product.colors?.[0]?.id, $event)
                        "
                    />

                    <v-icon
                        class="icon--selected"
                        icon="check"
                    />
                </div>
            </v-col>
        </template>
    </v-row>
</template>

<script setup>
import { useRouter } from 'vue-router';

import { useProductsStore } from '@/store/products';

import Product from './Product.vue';
import { useProductIds } from '@/composables/products/useProductIds';
import { useProductToSelections } from '@/composables/selections/useProductToSelections';

const props = defineProps({
    mode: {
        type: String,
        default: 'model'
    },
    isEditing: {
        type: Boolean,
        default: false
    },
    selectedProducts: {
        type: Array,
        default() {
            return [];
        }
    }
});

const productsStore = useProductsStore();

const emit = defineEmits(['toggle:selected-products']);

// Products selection
const { updateFavoriteProduct } = useProductToSelections();
const { getItemId, isSelectedProduct, selectProduct } = useSelectProduct();

/**
 * Reusable functions specific to this component
 */
function useSelectProduct() {
    const router = useRouter();

    // functions to get some kind of product ids
    const { getProductId, getProductColorId, getProductColorIds } = useProductIds();

    const getItemId = product => {
        return props.mode === 'model' ? getProductId(product) : getProductColorId(product);
    };

    const selectProduct = product => {
        props.isEditing
            ? emit('toggle:selected-products', getProductColorIds(product))
            : router.push({
                  name: 'pdp',
                  params: { productId: product.reference },
                  query: { colorId: product.colors[0].id }
              });
    };

    const isSelectedProduct = product => props.selectedProducts.includes(getProductColorId(product));

    return { getItemId, isSelectedProduct, selectProduct };
}
</script>

<style lang="scss" scoped>
.product-list__item {
    max-width: calc(100% / 4);
    min-width: calc(100% / 4);
    &-wrapper {
        height: 100%;
    }
}

@include responsive(tv) {
    .product-list__item {
        max-width: calc(100% / 5);
        min-width: calc(100% / 5);
    }
}
</style>
