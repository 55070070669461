<template>
    <v-snackbar
        v-model="isVisible"
        :color="notificationStore.getColor"
        :timeout="notificationStore.getTimeout"
        :location="notificationStore.getLocation"
        variant="flat"
        data-testid="notification"
        multi-line
    >
        <span
            class="body-medium text-black"
            v-text="notificationStore.getMessage"
        />

        <template #actions>
            <v-icon
                class="icon-close text-black"
                icon="close"
                size="small"
                @click="isVisible = false"
            />
        </template>
    </v-snackbar>
</template>

<script setup>
import { computed } from 'vue';
import { useNotificationStore } from '@/store/common/notification';

const notificationStore = useNotificationStore();

const isVisible = computed({
    get() {
        return notificationStore.isVisible;
    },
    set(newValue) {
        notificationStore.setStatus(newValue);
    }
});
</script>

<style lang="scss" scoped>
.icon-close {
    margin-right: 1.5rem;
}
</style>
