/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts } from './webfontloader';
import { createVuetifyCustomized } from './vuetify';
import pinia from '../store';
import router from '../router';
import helpers from '../helpers';
import errorLogger from './errorLogger';

// Locales
import i18n from './lang/i18n';
import { useI18n } from 'vue-i18n';

// For Dialogs
import { PromiseDialog } from 'vue3-promise-dialog';

const vuetify = createVuetifyCustomized({ i18n, useI18n });

export function registerPlugins(app) {
    loadFonts();

    // prettier-ignore
    app
        .use(i18n)
        .use(vuetify)
        .use(pinia)
        .use(router)
        .use(helpers)
        .use(PromiseDialog)
        .use(errorLogger);
}
