<template>
    <div class="scroller">
        <slot />
        <div ref="endOfScroller"></div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const emits = defineEmits(['load-more']);

const endOfScroller = ref(null);

const observer = new IntersectionObserver(entries => {
    if (entries[0].isIntersecting) {
        emits('load-more');
    }
});

onMounted(() => {
    observer.observe(endOfScroller.value);
});

onUnmounted(() => {
    observer.disconnect();
});
</script>
