<template>
    <v-text-field
        v-model="searchValue"
        variant="underlined"
        clear-icon="close"
        single-line
        clearable
        :rules="[() => searchValue?.length >= 3 || searchValue?.length === 0 || $t('filter.searchMinLength', 3)]"
        @keyup.enter="doSearch"
        @click:clear="doSearch"
    >
        <template #prepend-inner>
            <SemanticIcon
                icon="search"
                :size="23"
                @click="doSearch"
            />
        </template>
        <template #label>
            <span class="body-small-medium">{{ searchLabel }}</span>
        </template>
    </v-text-field>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { useSeasonStore } from '@/store/seasons';
import { useProductsStore } from '@/store/products';

import SemanticIcon from '@/components/Common/SemanticIcon.vue';

const { t } = useI18n();

const route = useRoute();

const searchValue = ref('');

const productsStore = useProductsStore();

const seasonsStore = useSeasonStore();

const searchLabel = computed(() => {
    return `${t('filter.searchIn')} ${seasonsStore.getCurrentSeasonName(route.params.seasonId)}`;
});

/**
 * Search for products
 */
const doSearch = () => {
    productsStore.setSearch(searchValue.value || '');
};
</script>

<style lang="scss" scoped>
:deep(.v-field-label) {
    color: #b1b1b1;
    height: 100%;
    top: 0;
}
:deep(.v-field__input) {
    padding: 0;
    margin-left: 0.375rem;
}
:deep(.v-field__outline) {
    margin-top: 0.2rem;
}
:deep(.v-field__prepend-inner) {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

:deep(.v-input__details) {
    margin-top: 0.6rem;
}
</style>
