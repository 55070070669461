// Utilities
import { defineStore } from 'pinia';
import ConfigsApiService from '@/services/ConfigsApiService';

const languages = {
    es: 'es-ES',
    en: 'en-US',
    it: 'it-IT',
    fr: 'fr-FR'
};

export const useLocaleStore = defineStore('locales', {
    state: () => ({
        locale: '',
        locales: []
    }),

    getters: {
        getLocale: state => state.locale || 'es',
        getLocales: state => state.locales,
        getLanguage: state => languages[state.locale] || 'es-ES'
    },

    actions: {
        /**
         * Set locale
         *
         * @param {String} payload
         */
        setLocale(payload) {
            this.locale = payload;
            localStorage.setItem('cnm-locale', payload);
        },

        /**
         * Set locales
         *
         * @param {Object} payload
         */
        setLocales(payload) {
            this.locale = localStorage.getItem('cnm-locale') || 'es';
            localStorage.setItem('cnm-locale', this.locale);
            this.locales = payload;
            localStorage.setItem('cnm-locales', JSON.stringify(payload));
        },

        /**
         *
         * @param {Object} payload
         *
         * @returns {Promise}
         */
        loadLocales() {
            return new Promise((resolve, reject) => {
                ConfigsApiService.getLocales()
                    .then(response => {
                        this.setLocales(response.data);
                        resolve();
                    })
                    .catch(err => reject(err));
            });
        },

        clearLocales() {
            this.locales = [];
            localStorage.removeItem('cnm-locales');
        }
    }
});
