{
  "common": {},
  "global": {
    "menuLeft": {
      "selections": "Favoritos"
    }
  },
  "selections": {
    "title": "Favoritos",
    "btnNew": "Nuevo Grupo",
    "addNameTitle": "Nombrar nuevo favorito",
    "placeHolderName": "Nombre del favorito",
    "btnMoveToCart": "Mover Favorito a la cesta",
    "returnToItem": "Volver a favorito",
    "dialog": {
      "deleteMessage": "Estás seguro de eliminar este Favorito? | ¿Estás seguro de eliminar estos {count} favoritos?",
      "deleteProducts": "¿Estás seguro de eliminar este producto de los favoritos? | ¿Estás seguro de eliminar estos {count} productos-colores de los favoritos?",
      "moveToCartMessage": "¿Estás seguro mover este favorito a la cesta?",
      "cartAlreadyExists": "Atención, la cesta ya contiene productos.",
      "saveTo": "Guardar en Favoritos"
    },
    "notification": {
      "addProduct": {
        "error": "Ocurrieron problemas guardando la selección del favorito"
      },
      "removeProduct": {
        "success": "Successfully removed from favorite"
      }
    }
  }
}
